@font-face {font-family: "Proxima";src: url("./fonts/Proxima-Nova-Bold.otf") format("opentype");font-weight: bold;font-style: normal;}
@font-face {font-family: "Proxima";src: url("./fonts/PROXIMANOVA-LIGHT.OTF") format("opentype");font-weight: 300;font-style: normal;}
@font-face {font-family: "Proxima";src: url("./fonts/PROXIMANOVA-REGULAR.OTF") format("opentype");font-weight: normal;font-style: normal;}
@font-face {font-family: "Proxima";src: url("./fonts/Proxima-Nova-Sbold.otf") format("opentype");font-weight: 600;font-style: normal;}
@font-face {font-family: "Proxima";src: url("./fonts/Proxima-Nova-Thin.otf") format("opentype");font-weight: 100;font-style: normal;}
@font-face {font-family: "Proxima";src: url("./fonts/Proxima-Nova-Xbold.otf") format("opentype");font-weight: 900;font-style: normal;}
body {font-family: "Proxima" !important;font-weight: normal;color: #3a3c3e;}
a{cursor: pointer;}
a:hover, a:active, a:focus,
a:hover > div, a:active > div, a:focus > div,
a > div:hover, a > div:active, a > div:focus,
div#account_info button.signout:focus {outline:none !important;}
.cur-poi{cursor: pointer !important;}
.navbar-nav li.nav-item a {font-size: 20px;color: #3a3c3e !important;padding: 7px 15px !important;}
.navbar-nav li.nav-item.colr a {color: #e85629 !important;}
.navbar-nav li.nav-item.colr a.btn.btn-orange{color: #fff !important;}
.navbar-nav li.nav-item.upper a {color: #0a6eb4 !important;}
.navbar-nav li.nav-item.button a {background: #0a6eb4;color: #fefeff !important;padding: 7px 10px !important;border-radius: 4px;font-weight: 600;border: 1px solid #0a6eb4;}
.navbar-nav li.nav-item.upper a.btn-blue:hover {background: white;color: #0a6eb4 !important;}
.btn.btn-blue:hover {background-color: white;color: #0a6eb4 !important;}
.btn.btn-blue {background-color: #0a6eb4;color: white;border-radius: 4px;border: 1px solid #0a6eb4;}
.btn.btn-white {border: 1px solid #0a6eb4;background: white;border-radius: 4px;}
.btn.btn-orange {background-color: #e85629;color: white;border-radius: 4px;border: 1px solid #e85629;}
.btn.btn-orange:hover {background-color: white;color: #e85629 !important;}
header .main-header {padding: 7px 0;}
header.user-admin .main-header {height: 60px;padding: 3px 0;}
.navbar-nav li.nav-item a:hover, .navbar-nav li.nav-item a.active {background: #0a6eb4;color: #fff !important;border-radius: 4px;padding: 7px 15px !important;}
.navbar-nav li.nav-item.button a:hover {background: transparent;color: #0a6eb4 !important;border: 1px solid #0a6eb4;padding: 7px 10px !important;}
.navbar-nav li.nav-item.colr a.btn.btn-orange:hover {background: #fff ;color: #e85629 !important;}
label.require:after {content: "*";color: #f30000;font-weight: 600;margin-left: 3px;}
header .main-header nav#mainNav {width: 100%;}
section.top-banner {background: url(./images/banner-top.png);background-size: 100% 100%;background-repeat: no-repeat;padding-bottom: 100px;}
section.top-banner .banner-content {width: 100%;margin-top: 100px;}
section.top-banner .banner-content h1.banner-title {font-size: 70px;color: white;font-weight: 900;margin-top: 30px;line-height: 85px;text-align: center;	margin-bottom: 0;text-transform: uppercase;}
section.top-banner .banner-content h1.banner-title span {font-weight: 100;color: #212121;text-transform: capitalize;}
section.top-banner .banner-content p {font-size: 18px;color: white;text-align: center;max-width: 750px;margin: 0 auto;}
section.top-banner .banner-bottom {margin-top: 40px;text-align: center;width: 100%;margin-bottom: 30px;}
section.top-banner .banner-bottom .down-arrow span {background: white;width: 50px;height: 50px;padding: 10px 10px;border-radius: 50%;margin-bottom: 50px;}
section.top-banner .banner-bottom .down-arrow:after {content: "";background: white;position: absolute;height: 65px;border: 1px solid white;bottom: 10px;left: 0;width: 1px;margin: 0 auto;right: 0;}
section.top-banner .banner-bottom .search-field {max-width: 960px;margin: 0 auto;background-color: rgba(0, 0, 0, 0.5);color: white;padding: 30px 30px;}
section.top-banner .banner-bottom .search-field input[type="text"] {width: 78%;height: 60px;font-size: 18px;float: left;box-shadow: none;border-radius: 4px;outline: none;margin-right:18px;color: #36383b;font-weight: 400;}
section.top-banner .banner-bottom .search-field button.genrate {width: 20%;background: white;height: 60px;border-radius: 4px;color: #212121;font-size: 20px;}
section.top-banner .banner-bottom .search-field p {font-size: 16px;text-align: left;margin-top: 5px;margin-bottom: 0;}
section.top-banner .banner-bottom .down-arrow {padding-bottom: 90px;position: relative;}
section.first-section span.heading {display: inline-block;margin: 0 auto;width: 100%;position: relative;text-align: center;padding-top: 140px;margin-bottom: 50px;}
section.first-section span.heading:before {content: "";position: absolute;top: 0;height: 100px;background: #0a6eb4;width: 1px;border: 1px solid #0a6eb4;top: 40px;left: 0;right: 0;margin: 0 auto;}
section.first-section span.heading h2 {display: inline-block;font-size: 36px;font-weight: bold;color: #3a3c3e;text-transform: uppercase;padding: 30px 0;line-height: 36px;width: 100%;}
section.first-section {background: url(./images/first-bk.png);background-repeat: no-repeat;background-size: 30%;background-position: right 10%;padding-bottom: 30px;}
.content-title {font-size: 36px;font-weight: bold;line-height: 32px;margin-top: 10px;margin-bottom: 20px;}
.content-info p {font-size: 18px;line-height: 22px;}
.content-title span {font-size: 18px;}
.btn.btn-cb {background: #0a6eb4;border: 1px solid #0a6eb4;color: white;font-size: 20px;font-weight: 600;padding: 9px 30px;border-radius: 30px;}
.btn.btn-cb:hover {background: #fff;color: #0a6eb4;}
.btn.btn-cw {background: #e85629;border: 1px solid #e85629;color: #fff;font-size: 20px;font-weight: 600;padding: 9px 30px;border-radius: 30px;}
.btn.btn-cw:hover {background: #fff;border: 1px solid #e85629;color: #e85629;}
.action-button button.btn.btn-cw {margin-right: 20px;}
.row.second,.row.third {margin-top: 70px;}
section.first-section .row.second span.heading,section.first-section .row.third span.heading {padding-top: 70px;margin-bottom: 50px;}
section.first-section .row.second span.heading:before,section.first-section .row.third span.heading:before {content: "";position: absolute;top: 0;height: 1px;background: #0a6eb4;width: 115px;border: 1px solid #0a6eb4;top: 10px;left: 0;right: 0;margin: 0 auto;}
section.first-section .row.second span.heading h2,section.first-section .row.third span.heading h2 {padding: 0 0;max-width: 100%;width: 100%;text-transform: none;}
section.first-section .row.second span.heading:after,section.first-section .row.third span.heading:after {content: "";position: absolute;width: 20px;left: 0;border-radius: 50%;height: 20px;border: 2px solid #0a6eb4;background-color: white;right: 0;margin: 0 auto;top: 0px;z-index: 9;}
section.first-section .row.second span.heading p,section.first-section .row.third span.heading p {font-size: 18px;padding: 0 40px;}
.row.second .item {text-align: center;box-shadow: 0 0 5px 0px #00000091;border-radius: 4px;padding: 20px;margin-bottom: 50px;position: relative;}
.row.second .item p {color: #434343;font-size: 18px;line-height: 22px;min-height: 110px;margin-bottom: 45px;}
.row.second .item span.title {font-size: 24px;font-weight: bold;color: #363636;}
.row.second .item .image {margin-bottom: 20px;margin-top: 20px;}
.row.second .item .action-button {position: absolute;bottom: -25px;left: 0;right: 0;}
.row.third .item {border: 1px solid #cccccc;border-radius: 4px;}
.row.fourth {margin-top: 30px;}
.row.fourth .item {border: 1px solid #cccccc;border-radius: 4px;}
section.before-footer {background: #0a6eb4;padding: 60px 0;text-align: center;}
section.before-footer .btn.btn-cw:hover {color: white;background: #0a6eb4;}
section.before-footer .btn.btn-cw {color: #0a6eb4;border-color: white;background: white;}
section.before-footer span,.before-footer span {font-size: 60px;line-height:65px;color: white;font-weight: 900;display:block;margin:0 0 25px;}
span.clr {color: #0a6eb4;}
footer {background: #f7f7f7;padding-top: 80px;}
footer .container p {color: #434343;margin-top: 20px;font-size: 18px;}
footer span.title {font-size: 24px;font-weight: 600;color: #3a3c3e;}
footer ul {padding: 0;margin-top: 10px;margin-bottom: 10px;display: inline-block;width: 100%;}
footer ul li {list-style: none;padding: 2px 0;}
footer ul li a {color: #3a3c3e;font-size: 16px;}
footer ul li a:hover {color: #0a6eb4;text-decoration: none;}
.socail-links ul li a {width: 35px;height: 35px;font-size: 16px;display: block;padding: 4px 0 3px;border: 2px solid;border-radius: 50%;}
.socail-links ul li {float: left;text-align: center;height: 35px;margin-right: 6px;}
.socail-links ul li:hover a,.socail-links ul li a:hover{border-color:#0a6eb4;color:#fff;background:#0a6eb4;}
.bottom-copyrights p.copyrights {font-size: 14px;color: #3a3c3e;margin: 10px 0;padding-left: 20px;width: 100%;text-align: center;}
a.readmore {color: #0a6eb4;}
footer ul li a .fas {margin-right: 5px;}
.bottom-copyrights {background: #e4e4e4;}
div#shorten_actions {background: white;padding: 8px 0 2px;margin-top: 10px;color: #3a3c3e;border-radius: 4px;}
div#shorten_actions ul#most_recent_links {display: inline-block;list-style: none;margin: 0;width: 100%;padding: 2px 10px;}
div#shorten_actions ul#most_recent_links span.long-link {float: left;padding: 8px 0;}
div#shorten_actions ul#most_recent_links > span {float: right;display:block;}
div#shorten_actions ul#most_recent_links span span.short-link {float: left;padding: 8px 0;margin-right: 20px;}
div#shorten_actions ul#most_recent_links span span.short-link a {text-decoration: none;}
/* div#shorten_actions ul#most_recent_links  span span {display:block;float:none;} */
div#shorten_actions ul#most_recent_links span.link-prev-text{display:inline-block;vertical-align:top;color:#000;}
div#shorten_actions ul#most_recent_links span span.copy{float:right;}
div#shorten_actions ul#most_recent_links span span.copy button {background: #edf2fe;border: none;color: black;padding: 8px 20px;border-radius: 8px;}
.container-box {max-width: 415px;margin: 40px auto;border: 1px solid #e3e6e7;border-radius: 4px;padding: 50px 53px;background: #f9f9f9;}
.button.susi-btn {width: 90px;color: #8f989d;background-color: #fff;box-shadow: none;border: 1px solid;height: 40px;padding: 0;line-height: 36px;display: inline-block;border-radius: 4px;text-align: center;}
.susi-with {display: block;text-align: center;color: #8f989d;font-size: 14px;font-weight: 400;line-height: 30px;margin: 5px 0;}
.susi-fields-wrapper label {width: 100%;margin-top: 10px;}
.susi-fields-wrapper input.text {width: 100%;padding: 8px 10px;border-radius: 4px;border: 1px solid #e3e6e7;box-shadow: none;}
.susi-fields-wrapper label a.forgot {font-size: 14px;float: right;}
.susi-fields-wrapper input.sign-up-in,.susi-fields-wrapper .submit {background: #0a6eb4;border: none;color: white;width: 100%;padding: 10px 0;border: 1px solid #0a6eb4;border-radius: 4px;margin-top: 10px;font-size: 18px;}
.separator:before {content: "";display: block;width: 100%;height: 1px;background: #e3e6e7;position: absolute;top: 50%;}
.separator span {padding: 5px 20px;background: #f9f9f9;color: #8f989d;position: relative;}
.container-box .separator {margin: 15px 0;font-size: 14px;font-weight: 300;text-align: center;}
.separator {position: relative;text-transform: uppercase;font-size: 12px;font-weight: 600;margin: 16px 0;}
.container-box .header h3.tagline {font-size: 25px;text-align: center;}
.button.susi-btn + .button.susi-btn {margin-left: 12px;}
.social-sign-in {margin-bottom: 20px;}
.container-box h1.header-logo {text-align: center;margin-bottom: 30px;}
.container-box .switch a {color: #0a6eb4;text-decoration: none;}
.button.susi-btn:hover {background-color: #f9f9f9;}
.susi-fields-wrapper input.sign-up-in:hover,.susi-fields-wrapper input.submit:hover {background: white;color: #0a6eb4;}
.gray-link {color: #a7a2a2;font-size: 14px;}
.container-box .text-center .switch {margin-bottom: 5px;font-size: 15px;}
.container-box p.gray-link {margin-bottom: 5px;}
.authFormFieldContainer {margin-bottom: 10px;}
.menu.clicked {width: 40px;height: 40px;}
.menu {width: 36px;height: 36px;position: absolute;z-index: 21;right: 0;top: 9px;}
.menu span {position: relative;margin-top: 9px;margin-bottom: 9px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;position: absolute;top: 50%;left: 50%;margin-left: -15px;margin-top: -1.5px;}
.menu span,.menu span::before,.menu span::after {display: block;width: 26px;right: 0;height: 3px;background-color: #0a6eb4;outline: 1px solid transparent;-webkit-transition-property: background-color, -webkit-transform;-moz-transition-property: background-color, -moz-transform;-o-transition-property: background-color, -o-transform;transition-property: background-color, transform;-webkit-transition-duration: 0.3s;-moz-transition-duration: 0.3s;-o-transition-duration: 0.3s;transition-duration: 0.3s;}
.menu span::before,.menu span::after {position: absolute;content: "";}
.menu span::before {top: -9px;width: 26px;}
.menu span::after {top: 9px;width: 26px;}
.menu.clicked span {background-color: transparent;}
.menu.clicked span::before {-webkit-transform: translateY(9px) rotate(45deg);-moz-transform: translateY(9px) rotate(45deg);-ms-transform: translateY(9px) rotate(45deg);-o-transform: translateY(9px) rotate(45deg);transform: translateY(9px) rotate(45deg);width: 26px;}
.menu.clicked span::after {-webkit-transform: translateY(-9px) rotate(-45deg);-moz-transform: translateY(-9px) rotate(-45deg);-ms-transform: translateY(-9px) rotate(-45deg);-o-transform: translateY(-9px) rotate(-45deg);transform: translateY(-9px) rotate(-45deg);}
.menu.clicked span:before,.menu.clicked span:after {background-color: #ffffff;}
.menu:hover {cursor: pointer;}
/*  NAV ========================================== */
#nav {background: #787a7b61;position: fixed;z-index: 20;color: #eee;top: 0;height: 100%;max-width: 100%;width: 100%;padding: 100px 40px 60px 40px;transform: translateX(100%);transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);}
#nav.show {-webkit-transform: translateX(0px);-moz-transform: translateX(0px);-ms-transform: translateX(0px);-o-transform: translateX(0px);transform: translateX(0px);}
#nav.show ul.main li {-webkit-transform: translateX(0px);-moz-transform: translateX(0px);-ms-transform: translateX(0px);-o-transform: translateX(0px);transform: translateX(0px);opacity: 1;}
.menu.clicked {position: fixed;z-index: 99;}
#nav.show ul.main li:nth-child(1) {transition-delay: 0.15s;}
#nav.show ul.main li:nth-child(2) {transition-delay: 0.3s;}
#nav.show ul.main li:nth-child(3) {transition-delay: 0.45s;}
#nav.show ul.main li:nth-child(4) {transition-delay: 0.6s;}
#nav.show ul.main li:nth-child(5) {transition-delay: 0.75s;}
#nav.show ul.main li:nth-child(6) {transition-delay: 0.9s;}
#nav.show ul.main li:nth-child(7) {transition-delay: 1.05s;}
#nav.show ul.main li:nth-child(8) {transition-delay: 1.2s;}
#nav.show ul.main li:nth-child(9) {transition-delay: 1.35s;}
#nav.show .about,#nav.show .social,#nav.show ul.sub {-webkit-transform: translateY(0px);-moz-transform: translateY(0px);-ms-transform: translateY(0px);-o-transform: translateY(0px);transform: translateY(0px);opacity: 1;transition-delay: 0.85s;}
#nav ul.main {list-style-type: none;}
#nav ul.main li {-webkit-transform: translateX(40px);-moz-transform: translateX(40px);-ms-transform: translateX(40px);-o-transform: translateX(40px);transform: translateX(40px);opacity: 0;-webkit-transition: all 0.3s ease;-moz-transition: all 0.3s ease;-o-transition: all 0.3s ease;transition: all 0.3s ease;float: none;list-style: circle;color: #fff;}
#nav ul.main li:last-of-type {margin-bottom: 0px;}
#nav ul.main li a {color: #ffffff;text-decoration: none;text-transform: uppercase;font-size: 1rem;display: block;padding: 10px 0;-webkit-transition: all 0.3s ease;-moz-transition: all 0.3s ease;-o-transition: all 0.3s ease;transition: all 0.3s ease;}
#nav ul.main li a span {color: #b7ac7f;}
#nav ul.main li a:hover {color: #b7ac7f;}
#nav ul.sub {list-style-type: none;margin-top: 40px;-webkit-transform: translateY(30px);-moz-transform: translateY(30px);-ms-transform: translateY(30px);-o-transform: translateY(30px);transform: translateY(30px);opacity: 0;-webkit-transition: all 0.4s ease;-moz-transition: all 0.4s ease;-o-transition: all 0.4s ease;transition: all 0.4s ease;}
#nav ul.sub li {margin-bottom: 10px;}
#nav ul.sub li:last-of-type {margin-bottom: 0px;}
#nav ul.sub li a {color: #ffffff;font-family: "Raleway", sans-serif;letter-spacing: 1px;font-size: 0.9rem;text-decoration: none;-webkit-transition: all 0.3s ease;-moz-transition: all 0.3s ease;-o-transition: all 0.3s ease;transition: all 0.3s ease;}
#nav ul.sub li a:hover {color: #b7ac7f;}
header .links ul li a {color: #0a6eb4;}
header .links ul {list-style: none;padding: 0;font-size: 20px;margin: 0;}
header a.logo {float: left;margin-top: 0px;}
header.user-admin a.logo {margin-top: 10px;}
header .links {float: left;}
header {display: inline-block;width: 100%;border-bottom: 1px solid #f1f1f1;}
header .links ul li .dropdown-menu {padding: 0;min-width: 240px;top: 4px !important;}
.dropdown-item.active,.dropdown-item:active {color: #fff;text-decoration: none;background-color: #0a6eb4;}
.dropdown-item:hover,.dropdown-item:focus {color: #ffffff !important;text-decoration: none !important;background-color: #0a6eb4 !important;}
header .links ul li a {color: #0a6eb4;padding: 10px 15px;}
header form {float: right;margin-top: 6px;}
header .menu-outsite {display: inline-block;width: calc(100% - 35px);}
header form {float: right;margin-top: 8px;max-width: 280px;position: relative;margin-right: 10px;width: 100%;}
header form input {width: 100% !important;border-color: #0a6eb4 !important;border-radius: 25px !important;}
header form.form-inline button {position: absolute;right: 10px;}
header form button .fas {color: #0a6eb4;}
header .menu-outsite .create {float: right;margin-top: 6px;padding-right: 20px;border-right: 1px solid #0a6eb4;}
header .menu-outsite .create a#navbarcreate {background: #0a6eb4;color: white;border-radius: 4px;padding: 9px 27px;border: 1px solid #0a6eb4;}
header .menu-outsite .create .dropdown-menu.show {padding: 0;min-width: 100%;}
header .menu-outsite span.group {float: right;border-left: none;margin-left: 0;margin-top: 4px;}
header .menu-outsite span.group button.btn {border: 1px solid #0a6eb4;color: #0a6eb4;padding: 10px 15px;margin-left: 20px;}
header .menu-outsite span.group button.btn:hover {background: #0a6eb4;color: white;}
header .menu-outsite .create a#navbarcreate:hover {background: white;color: #0a6eb4;}
header .menu-outsite .dropdown-menu.show a.dropdown-item {font-size: 13px;padding-right: 10px;}
.action-sheet--header {border-bottom: 1px solid;display: inline-block;padding: 20px 15px;width: 100%;}
.action-sheet--header h4.action-sheet--header-text {float: left;margin: 0;font-size: 17px;font-weight: 600;color: white;text-transform: uppercase;line-height:24px;}
.action-sheet--header a.action-sheet--upsell {float: left;color: white;font-size: 14px;margin-left: 20px;margin-top: 0;}
.action-sheet--content-wrapper {overflow-y: auto;height: calc(100vh - 68px);padding: 20px 15px 70px 15px;overflow-x: unset;width: 100%;}
.action-sheet--content-wrapper .user-account--ALTERNATE img.user-account--image {float: left;width: 60px;}
.action-sheet--content-wrapper .user-account--ALTERNATE .user-account--user-info {width: 160px;float: left;margin-left: 10px;font-size: 14px;padding: 10px 0;line-height: 16px;}
.user-account--ALTERNATE {cursor: pointer;border-bottom: 1px solid #35637c;display: inline-block;width: 100%;padding-bottom: 15px;}
.checklist-row--MAIN {cursor: pointer;display: inline-block;width: 100%;vertical-align: top;}
.action-sheet--content-wrapper h5.action-sheet--brand-view-header {margin-top: 20px;border-bottom: 1px solid #35637c;font-size: 18px;padding-bottom: 5px;text-transform: uppercase;}
.hide-desktop.links p.select-item--label {font-size: 15px;}
.hide-desktop.links {margin-bottom: 40px;margin-top: 30px;}
.checklist-row--MAIN,.select-item--ALTERNATE {border-bottom: 1px solid #35637c;padding-bottom: 8px;padding-top: 8px;position: relative;padding-right:23px;}
.checklist-row--MAIN .checklist-row--text {font-size: 15px;font-weight: normal;float: left;}
.action-sheet--content-wrapper p.select-item--label {margin: 0;}
.action-sheet--content-wrapper .marketing-module--GHOST p.marketing-module--text {margin: 0;}
.action-sheet--content-wrapper .marketing-module--GHOST {margin-top: 20px;background: #eff7fc;border-radius: 4px;color: #073e65;padding: 5px 10px;font-size: 13px;}
.action-sheet--content-wrapper a:hover {color: #00b1f8;}
.action-sheet--content-wrapper a {color: white;text-decoration: none;}
.action-sheet--content-wrapper .marketing-module--GHOST p.marketing-module--text a {color: #0a6eb4;}
span.checklist-row--icon {float: right;margin-right: 10px;line-height: 12px;}
.user-account--ALTERNATE .user-account--icon span.selector-icon {font-size: 35px;}
.user-account--ALTERNATE .user-account--icon {float: right;margin-right: 5px;}
.action-sheet--content-wrapper form {margin: 0;}
.input-field--MAIN .input-field--input-wrapper input,.text-field--MAIN .text-field--textarea-wrapper textarea,.input-field--MAIN select {background: transparent;border: none;color: #ada8a8;width: 100%;}
.sticky-upgrade-button--column .sticky-upgrade-button--column {width: 100% !important;text-align: center;}
.input-field--MAIN,.text-field--MAIN {border: 1px solid #35637c;padding: 4px 0;margin-bottom: 10px;}
.action-sheet--content-wrapper span.deactivate-account--link {display: inline-block;vertical-align:top;text-align: center;margin-bottom: 5px;margin-top: 5px;cursor: pointer;padding:5px 15px;background:#ffb4b4;color:#04263e;border:1px solid #ffb4b4;border-radius:4px;-o-transition:all ease-in-out 0.3s;-ms-transition:all ease-in-out 0.3s;-moz-transition:all ease-in-out 0.3s;-webkit-transition:all ease-in-out 0.3s;transition:all ease-in-out 0.3s;}
.action-sheet--content-wrapper span.deactivate-account--link:hover{background:none;color:#ffb4b4;}
.tooltip--ALTERNATE {position: relative;width: auto;display: inline-block;margin-left: 5px;}
.tooltip--content.tooltip--content-narrow {position: absolute;background: grey;z-index: 9;right: 0;font-size: 14px;color: white;width: 240px;display: none;padding: 10px 10px;}
.action-sheet--button-wrapper {margin-top: 30px;display: inline-block;right: 0;text-align: center;width: 100%;height: 50px;}
.action-sheet--button-wrapper button,.action-sheet--content-wrapper button {position: unset;margin: 0 auto;width: 80%;background: white;border: 1px solid white;padding: 8px 0;color: #073e65;margin-bottom: 15px;border-radius: 4px;}
.action-sheet--button-wrapper button:hover,.action-sheet--content-wrapper button:hover {background: transparent;color: white;}
.email-info--wrapper .checklist-row--MAIN {position: unset;border-bottom: 0;padding: 0;}
.checklist-row--MAIN {cursor: pointer;}
.email-info--wrapper span.checklist-row--icon {position: absolute;right: 0;top: 32%;text-align: center;vertical-align: middle;font-size: 18px;}
.email-info--wrapper {line-height: 20px;position: relative;margin-bottom: 10px;padding-bottom: 10px;border-bottom: 1px solid #35637c;font-size: 14px;width: 100%;cursor: pointer;}
.checklist-row--MAIN .checklist-row--text {font-size: 15px;font-weight: normal;float: left;}
.email-info--wrapper .checklist-row--text:hover {color: #3087ea;}
.email-info--wrapper .checklist-row--MAIN.neutral {line-height: 7px;}
.action-sheet--header a {display: block;width: auto;float: left;margin-right: 10px;font-size: 18px;line-height: 18px;margin-top: 0px;cursor: pointer;}
span.email-verified {color: #44fb18;}
span.email-unverified {color: #fd2429;}
a.email-verify-linkout {color: #00b6ff !important;}
p.action-sheet--text {margin-top: 10px;}
p.action-sheet--text a {color: #00b1f8 !important;}
.tooltip--ALTERNATE:hover .tooltip--content.tooltip--content-narrow {display: block;}
.text-field--MAIN {border-color: #d3d4d7;}
.text-field--MAIN,.input-field--MAIN,.input-field--ALTERNATE,.input-field--CUSTOM {width: 100%;border: none;border-radius: 3px;position: relative;margin-bottom: 1rem;}
.action-sheet--content-wrapper .action-sheet--ghost-button-wrapper {margin: 10px 0;text-align: center;}
.action-sheet--content-wrapper .action-sheet--ghost-button-wrapper .button--GHOST-SECONDARY {width: 80%;background: white;border: 1px solid white;padding: 7px 0;font-size: 16px;color: #073e65;margin: 0 auto;cursor: pointer;display: inline-block;border-radius: 4px;}
.action-sheet--item {color: white;position: relative;padding: 10px 15px 10px 0;border-bottom: 1px solid #d3d4d7;}
.session-log--MAIN {line-height: 15px;}
p.session-log--action {font-weight: bold;}
.session-log--MAIN p {display: inline-block;margin-right: 5px;font-size: 13px;margin-bottom: 0;color: #eee;}
h5.action-sheet--content-header {display: inline-block;width: 100%;color: white;}
.empty-state--RESULTS .no-connected-apps-icon {color: #d3d4d7;font-size: 35px;}
.empty-state--header {color: #cfcfd0;font-size: 14px;margin-top: 10px;margin-bottom: 10px;text-align: center;}
.empty-state--gradient-wrapper {text-align: center;padding:0 0 10px;}
.button--GHOST-SECONDARY:hover {background: transparent !important;color: white !important;}
.select-item--MAIN select.selectpicker {width: 100%;padding: 8px 10px;margin: 15px 0;background: transparent;color: white;border-radius: 4px;}
.select-item--MAIN select.selectpicker option {color: black;}
.select-item--MAIN-b {padding: 5px;cursor: pointer;margin-bottom: 10px;-webkit-border-radius: 3px;-moz-border-radius: 3px;-khtml-border-radius: 3px;border-radius: 3px;border: 1px solid #d3d4d7;display: inline-block;width: 100%;}
.select-item--MAIN-b p.select-item--label:hover {color: #3087ea;}
.select-item--MAIN-b p.select-item--label {float: left;}
.action-sheet--content-header {border-bottom: 1px solid #35637c;padding: 5px 0;margin-bottom: 10px;color: white;font-size: 16px;margin-top: 0;text-transform: uppercase;}
.action-sheet--content-header span.action-sheet--org-name {font-size: 16px;font-weight: bold;}
.action-sheet--content-header span.personal-icon {margin-right: 10px;font-size: 25px;}
.radio-check--LARGE .checkbox--input {display: none;}
.radio-check--LARGE label.checkmark-icon {color: #00b1f8;margin:0;}
.checklist-row--MAIN .checklist-row--text:hover {color: #00b1f8;}
.radio-check--LARGE label.checkmark-icon svg{width:13px;}
.checklist-row--MAIN .checklist-row--text .checklist-row--secondary-label {margin-left: 10px;}
div.action-sheet--text {font-size: 15px;}
.select-item--MAIN-b.b {border: none;border-bottom: 1px solid #35637c;}
.connected-account--sub-header {border-bottom: 1px solid #35637c;padding: 5px 5px;margin-bottom: 15px;color: white;font-weight: 600;text-transform: uppercase;font-size: 16px;}
.advanced-settings--domain-settings {border-top: 1px solid #35637c;}
.action-sheet--content-header.no-b {border: none;margin-bottom: 0;margin-top: 10px;}
.select-item--ALTERNATE.stl {display: inline-block;width: 100%;}
.select-item--ALTERNATE.stl p.select-item--label {float: left;}
.select-item--ALTERNATE.stl span.external-link-icon {float: right;}
.action-sheet--content-wrapper .marketing-module--GHOST p.marketing-module--header {margin-bottom: 0;}
.action-sheet--header a.action-sheet--upsell span.unlock-icon {margin-right: 5px;}
.action-sheet--content-wrapper .marketing-module--GHOST p.marketing-module--text a span.arrow-icon {margin-left: 3px;}
.action-sheet--content-header.cust span.action-sheet--text a {color: #3087ea;cursor: pointer;text-transform: capitalize;}
.action-sheet--content-header.cust span.action-sheet--text {float: right;margin-top: 10px;margin-bottom: 0;}
.action-sheet--content-header.cust span.action-sheet--org-name {float: left;margin-top: 10px;}
.action-sheet--content-header.cust span.personal-icon {float: left;}
.action-sheet--content-header.cust {display: inline-block;width: 100%;padding-bottom: 0;}
.select-item--DISABLED {padding: 6px 10px;margin-bottom: 10px;border: 1px solid;border-radius: 4px;background: #ada5a5;}
.action-sheet--content-wrapper .action-sheet--tooltip {text-align: center;line-height: 18px;}
.action-sheet--wrapper-MAIN form {margin: 0;float: none;max-width: 100%;padding-bottom: 70px;}
.dynamic-search-list--ALTERNATE .search--ALTERNATE {display: inline-block;width: 100%;margin-bottom: 10px;margin-top: 5px;}
.dynamic-search-list--ALTERNATE .search--ALTERNATE input {background: transparent;border: 1px solid white !important;padding: 5px 10px;color: white;}
.dynamic-search-list--ALTERNATE .search--ALTERNATE input::-webkit-input-placeholder {color:#fff;}
.dynamic-search-list--ALTERNATE .search--ALTERNATE input:-moz-placeholder {color:#fff;opacity:  1;}
.dynamic-search-list--ALTERNATE .search--ALTERNATE input::-moz-placeholder {color:#fff;opacity:  1;}
.dynamic-search-list--ALTERNATE .search--ALTERNATE input:-ms-input-placeholder {color:#fff;}
.dynamic-search-list--ALTERNATE .search--ALTERNATE input::-ms-input-placeholder {color:#fff;}
.dynamic-search-list--ALTERNATE .search--ALTERNATE input::placeholder {color:#fff;}
.dynamic-search-list--ALTERNATE a.search-view-all {float: right;cursor: pointer;}
.dynamic-search-list--ALTERNATE h5.dynamic-search-list--header {font-size: 15px;/*float: left;*/}
.action-sheet--button-wrapper-visible {text-align: center;margin-top: 0;}
.dynamic-search-list--ALTERNATE a.search-view-all {float: right;cursor: pointer;color: #01abf1 !important;}
.action-sheet--content-wrapper .action-sheet--link-channel {color: #01abf1;cursor: pointer;}
.action-sheet--content-wrapper .action-sheet--link-bottom {text-align: center;margin-top: 10px;color: #01a8ed;cursor: pointer;}
.dynamic-checklist--MAIN .search--ALTERNATE input {width: 100%;background: transparent;border: 1px solid white;border-radius: 25px;padding: 10px 10px;margin-bottom: 15px;color: white;}
.dynamic-checklist--item .input-field--ALTERNATE {border-right: 0;border-left: 0;border-radius: 0;padding: 5px 0;}
.dynamic-checklist--item .input-field--ALTERNATE input.input-field--input {width: 100%;padding: 5px 10px;background: transparent;border: none;color: white;}
.dynamic-checklist--item .input-field--ALTERNATE label.input-field--label {display: none;}
.dynamic-checklist--MAIN .dynamic-checklist--item span.dynamic-checklist--icon {position: absolute;right: 6px;top: 12px;display: none;}
.dynamic-checklist--MAIN .dynamic-checklist--item {position: relative;}
.dynamic-checklist--MAIN .dynamic-checklist--item:focus span.dynamic-checklist--icon {display: block;}
header.user-admin {background: #f7f7f7;}
header.user-admin span.navigation--header.m-hide {font-size: 18px;text-transform: uppercase;margin-left: 10px;margin-top: 14px;display: inline-block;font-weight: 600;color: #0a6eb4;}
header.user-admin.manage-account span.group {border: none;}
.layout--wrapper {margin-top: 50px;margin-bottom: 50px;}
.layout--wrapper ul.layout--navigation li a.clickable-item {cursor: pointer;padding: 5px 10px;display: inline-block;width: 100%;font-size: 18px;color: #3a3c3e;}
.layout--wrapper ul.layout--navigation {padding: 0;list-style: none;}
.layout--navigation-item.selected {background: #ebecee;width: 85%;}
.layout--navigation-item.selected:before {content: "";position: absolute;width: 0;height: 0;border-top: 14px solid transparent;border-bottom: 14px solid transparent;border-left: 20px solid #ebecee;top: 0;right: -20px;}
li.layout--navigation-item {position: relative;margin-bottom: 5px;border-radius: 5px 3px 3px 5px;}
.layout--navigation-item.selected:before {content: "";position: absolute;width: 0;height: 0;border-top: 19px solid transparent;border-bottom: 18px solid transparent;border-left: 22px solid #ebecee;top: 0;right: -21px;}
.layout--navigation-item:hover:before {content: "";position: absolute;width: 0;height: 0;border-top: 19px solid transparent;border-bottom: 18px solid transparent;border-left: 22px solid #ebecee;top: 0;right: -21px;}
.layout--navigation-item:hover:after {content: "";position: absolute;width: 0;height: 0;border-top: 19px solid transparent;border-bottom: 18px solid transparent;border-left: 20px solid #fff;top: 0;right: -19px;}
.layout--navigation-item.selected:hover:after {display: none;}
.admin-section--MAIN span.account-detail--header,.admin-section--MAIN .admin-section--header {font-size: 25px;margin-bottom: 20px;display: inline-block;margin-top: 10px;}
.admin-section--MAIN button.button--SOLID-PRIMARY,
.customDomainLoaderHolder .button--SOLID-PRIMARY {background: #0a6eb4;color: white;border: 1px solid #0a6eb4;padding: 10px 15px;border-radius: 4px;}
.admin-section--MAIN button.button--SOLID-PRIMARY:hover,
.customDomainLoaderHolder .button--SOLID-PRIMARY:hover{border-color:#0a6eb4;color:#0a6eb4;background:#fff;text-decoration: none;}
.admin-section--MAIN button.button--SOLID-DANGER {background: #f00404;color: white;border: 1px solid #f00404;padding: 10px 15px;border-radius: 4px; float: right; margin-top: 10px;}
.layout--navigation-item:hover {box-shadow: 0 0 0 1px #ebecee;width: 85%;}
.account-detail--item {padding: 10px 0;border-top: 1px solid #d3d4d7;border-bottom: 1px solid #d3d4d7;display: inline-block;width: 100%;}
.admin-section--MAIN a.admin-section--cta {float: right;}
.tabs-outer.account nav.nav.nav-tabs {border-top: 1px solid #d3d4d7;border-bottom: 1px solid #d3d4d7;margin-bottom: 10px;}

.tabs-outer.account nav.nav.nav-tabs .nav-link {padding: 10px 5px;margin-right: 10px;color: #3a3c3e;}
.tabs-outer.account nav.nav.nav-tabs .nav-link:hover,
.tabs-outer.account nav.nav.nav-tabs .nav-link.active {background: none;color: #0a6eb4;border:1px solid;border-color: transparent transparent #0a6eb4;border-radius: 0;font-weight: 400;}
.nav.nav-pills.layout--navigation a.nav-link {cursor: pointer;padding: 5px 10px;display: inline-block;width: 90%;font-size: 18px;color: #3a3c3e;margin-bottom: 5px;}
.nav.nav-pills.layout--navigation a.nav-link.active {background: #0a6eb4;color: white;}
.nav.nav-pills.layout--navigation a.nav-link:hover {background: #0a6eb4;color: white;}
.nav.nav-pills.layout--navigation a.nav-link:hover span.unlock-icon {color: white;}
.nav.nav-pills.layout--navigation a.nav-link span.unlock-icon {margin-left: 10px;color: #0a6eb4;font-size: 15px;}
.admin-section--MAIN .account-detail--content-header {font-size: 16px;font-weight: 600;margin-top: 25px;margin-bottom: 5px;}
.account-detail--type {padding-top: 0;display: inline-block;width: 100%;}
.account-detail--type .account-detail--name {float: left;font-size: 25px;background: #e85629;color: white;padding: 25px 35px;border-radius: 14px;width: 260px;line-height: 30px;text-align: center;text-transform: uppercase;}
.account-detail--type .account-detail--info {float: left;width: calc(100% - 260px);}
.account-detail--type .account-detail--info a.item-detail--link {float: right;cursor: pointer;color: #0a6eb4;}
.account-detail--type .account-detail--info ul {list-style: none; color:#0a6eb4;}
.account-detail--item span.account-detail--member-desc {float: left;color: #aba9a9;}
.account-detail--item .name {float: left;width: 200px;word-break: break-all;}
.account-detail--item a.item-detail--link {float: right;cursor: pointer;color: #0a6eb4 !important;}
.rate-limit-progress-bar--MAIN:last-child {margin: 0;border-bottom: 1px solid #d3d4d7;}
.rate-limit-progress-bar--MAIN {border-top: 1px solid #d3d4d7;padding: 10px 0;margin-top: 20px;}
.rate-limit-progress-bar--header {float: left;width: 16.4rem;}
.rate-limit-progress-bar--stat {display: inline-block;width: 50%;}
.rate-limit-progress-bar--MAIN .rate-limit-progress-bar--total {background-color: #d3d4d7;width: 70%;position: relative;height: 10px;float: left;margin-top: 6px;margin-right: 5px;}
.rate-limit-progress-bar--usage {position: absolute;height: 10px;width: 0;background: #0a6eb4;}
.rate-limit-progress-bar--MAIN a.rate-limit-progress-bar--link {float: right;font-size: 15px;color: #0a6eb4;}
.admin-section--MAIN .account-detail--content-header a.item-detail--link {float: right;cursor: pointer;color: #0a6eb4;}
.account-detail--item + .account-detail--item {border-top: 0;}
.account-detail--item span.stat {float: right;font-size: 15px;color: #aba8a8;}
.admin-section--MAIN table.table {margin-top: 50px;}
.admin-section--top .admin-section--item p.marketing-module--text {margin: 0;color: #8a8a8a;}
.admin-section--top .admin-section--item {padding: 5px 0;border-top: 1px solid #d3d4d7;border-bottom: 1px solid #d3d4d7;}
.admin-section--MAIN .empty-state--BITLINKS {margin-top: 10px;font-size: 14px;}
.empty-state--link {color: #0a6eb4;text-decoration: none;cursor: pointer;}
.empty-state--gradient-wrapper h4.empty-state--header {color: #000;font-size: 16px;font-weight: 600;}
.empty-state--RESULTS {margin-bottom: 10px;}
.admin-section--MAIN .empty-state--BITLINKS span.no-bitlinks-icon {font-size: 40px;color: #fffefe;}
.modal-footer .button--GHOST-SECONDARY,.button--GHOST-SECONDARY {color: #0a6eb4;background-color: transparent;border: 1px solid #0a6eb4;padding: 5px 30px;font-size: 18px;margin-right: 20px;border-radius: 4px;}
.modal-footer .button--SOLID-SECONDARY,.button--SOLID-SECONDARY {background-color: #0a6eb4;border: 1px solid #0a6eb4;color: #fff;padding: 5px 30px;font-size: 18px;border-radius: 4px;}
.button--SOLID-SECONDARY:disabled {background-color:#919597;color:#fff;border-color:#919597;}
.modal-footer .button--GHOST-SECONDARY:hover {background: #0a6eb4 !important;}
.modal-footer .button--SOLID-SECONDARY:hover {color: #0a6eb4;background: transparent;}
.modal-dialog {max-width: 650px !important;}
.modal-header .modal-title {font-size: 20px;font-weight: 600;text-align: left;margin-bottom: 0;width: 100%;color: white;}
.modal-header button.close {margin-top: 0px;margin-right: 10px;font-size: 35px;padding-top: 0;padding-right: 0;padding-bottom: 0;color: white;opacity: 1;position: absolute;top:7px;right: 4px;padding-left:0;}
.modal-header button.close:focus{outline:none;}
.modal-header {padding: 10px 55px 10px 10px !important;border: none;background: #0a6eb4;text-align: center;border-radius: 0 !important;}
.modal-text.account_details button {margin-top: 30px;background: #0a6eb4;margin-bottom: 15px;border: 1px solid #0a6eb4;color: white;display: inline-block;padding: 10px 0;width: 100%;max-width: 220px;}
.modal-body .modal-text {width: 100%;margin: 0 auto;font-size: 16px;max-width: 350px;text-align: center;margin-bottom: 0;margin-top: 20px;}
div#app_list .select-item--CUSTOM {border-bottom: 1px solid #35637c;padding: 10px 0;cursor: pointer;}
div#app_list .action-sheet--header-rule {border-bottom: 1px solid #35637c;padding: 10px 0;color: white;text-transform: uppercase;font-weight: 600;}
div#app_details button.button--GHOST-SECONDARY {background: transparent;color: white;}
div#app_details .action-sheet--content-wrapper .action-sheet--text-sm {word-break: break-all;font-size: 14px;}
div#app_details .action-sheet--content-header {border: none;margin: 0;font-size: 15px;color: white;font-weight: bold;}
.action-sheet--seperator {border: 1px solid #35637c;margin-top: 10px;margin-bottom: 10px;}
div#app_details button.button--GHOST-SECONDARY:hover {background: white !important;color: #073e65 !important;}
.action-sheet--wrapper-MAIN form label.text-field--label {text-transform: uppercase;font-weight: 600;font-size: 14px;}
textarea:focus,input:focus,select:focus {outline: none;box-shadow: none !important;}
.connected-account--MAIN {position: relative;border-bottom: 1px solid #35637c;overflow: auto;padding-bottom: 10px;margin-bottom: 10px;}
.connected-account--MAIN span.social-icon {float: left;font-size: 25px;padding: 7px 8px;border: 1px solid;line-height: 22px;border-radius: 50%;}
.connected-account--MAIN a.close-icon {float: right;margin-top: 10px;}
.connected-account--MAIN .connected-account--account-name {width: 70%;float: left;margin-left: 10px;font-size: 15px;line-height: 15px;color: white;margin-top: 6px;font-weight: 600;}
.connected-account--MAIN .connected-account--date {float: left;margin-left: 10px;width: 70%;color: #cec4c4;font-size: 13px;}
.action-sheet--wrapper-MAIN {width: calc(100% + 0px);overflow: hidden;background: #073e65;max-width: 320px;right: 0;position: absolute;height:100vh;}
.bitlink--copy-wrapper .bitlink--copy-tooltip {display: none;}
.bitlink--copy-interface {display: inline-block;width: 100%;}
#nav.show .bitlink--copy-interface a.bitlink--copyable-text {word-break: break-all;color: #00b1f8 !important;}
.bitlink--copy-interface .item--action-buttons {padding-top:5px;float:none;margin-left: 5px;}
.bitlink--copy-wrapper {border-bottom: 1px solid;margin-bottom: 10px;padding:5px 15px;}
.bitlink--copy-interface .item--action-buttons button.button--ACTION {font-size: 12px;background: transparent;border: 1px solid #35637c;color: white;padding: 2px 15px;margin:0 5px 5px 0;}
.bitlink--copy-interface .item--action-buttons button.button--ACTION:hover {background: white;color: #073e65;}
.All-links .items .bitlink--copy-interface .item--action-buttons button.button--ACTION {font-size: 17px;background: transparent;border: none;color: #1466ba;padding: 0;margin-left: 20px;}
/* .bitlink--copy-interface a.bitlink--copyable-text {font-size: 14px;color: #0a6eb4 !important;margin-top: 9px;} */
time.action-sheet--created-date {font-size: 14px;}
.action-sheet--text a.archive-link {float: right;font-size: 14px;cursor: pointer;color: #00b1f8 !important;}
.coachmark-wrapper .input-field--CUSTOM {padding: 10px 0px;display: inline-block;width: 100%;position: relative;}
.coachmark-wrapper .input-field--CUSTOM .input-field--static-value {border:1px solid #fff;border-radius:4px;padding:5px;}
.coachmark-wrapper .input-field--CUSTOM .input-field--input-wrapper input.input-field--input {background: no-repeat;border: none;color: #ada8a8;}
.coachmark-wrapper .input-field--CUSTOM label.input-field--label {width: 100%;color: white;text-transform: uppercase;}
.search--ALTERNATE input.search--input-field {background: transparent;border: 1px solid white;padding: 10px 15px;color: white;}
.search--ALTERNATE input.search--input-field::-webkit-input-placeholder {color:#fff;}
.search--ALTERNATE input.search--input-field:-moz-placeholder {color:#fff;opacity:  1;}
.search--ALTERNATE input.search--input-field::-moz-placeholder {color:#fff;opacity:  1;}
.search--ALTERNATE input.search--input-field:-ms-input-placeholder {color:#fff;}
.search--ALTERNATE input.search--input-field::-ms-input-placeholder {color:#fff;}
.search--ALTERNATE input.search--input-field::placeholder {color:#fff;}
div#edit_bitlink .action-sheet--content-wrapper {height: 67vh;}
div#account_details .modal-dialog {max-width: 800px;}
div#account_details .buttons-list button.btn {background: #0a6eb4;margin-bottom: 15px;border: 1px solid #0a6eb4;color: white;display: inline-block;padding: 10px 0;width: 100%;}
div#account_details .buttons-list button.btn:hover {background: white;color: #0a6eb4;}
.modal-text.account_details {width: 100%;max-width: 100%;margin-top: 0;font-size: 18px;margin-bottom: 10px;}
div#account_details .modal-title {	font-size: 30px;}
div#account_details .progrees-bar {display: inline-block;width: 100%;background: lightgray;height: 10px;}
.progrees-bar .procees-calculate {width: 50%;height: 10px;background: #0a6eb4;}
div#account_details .modal-header {padding: 0;}
.inpput-field {text-align: center;margin-bottom: 30px;}
.inpput-field label {width: 100%;font-size: 18px;text-align: center;}
.inpput-field input {width: 100%;max-width: 500px;padding: 0 10px;height: 40px;}
.btn-primary.focus,.btn-primary:focus {box-shadow: none !important;}
.fields-list button.btn.btn-primary.dropdown-toggle {background: transparent;color: black;width: 100%;text-align: left;padding: 10px 10px;margin-bottom: 20px;border: 1px solid lightgrey;}
.fields-list .dropdown-menu {width: 100%;}
.action button.btn {background: #0a6eb4;margin-bottom: 15px;border: 1px solid #0a6eb4;color: white;display: inline-block;padding: 10px 0;width: 100%;}
.action button.btn:hover {background: white;color: #0a6eb4;}
.action.row.justify-content-center {width: 100%;}
.procees-calculate.full {width: 100%;}
.modal-text.edit_account_info,.modal-text.edit_billing_info {max-width: 100%;width: 100%;}
.modal-text.edit_account_info .form-group,.modal-text.edit_billing_info .form-group {text-align: left;margin-bottom: 30px;}
.modal-text.edit_account_info form button,.modal-text.edit_billing_info form button {margin-top: 0;background: #0a6eb4;margin-bottom: 15px;border: 1px solid #0a6eb4;color: white;display: inline-block;padding: 10px 0;width: 100%;max-width: 220px;}
.modal-text.edit_account_info form button:hover,.modal-text.edit_billing_info form button:hover {background: white;color: #0a6eb4;}
.single-invoice-summary--layout {padding: 7%;}
.single-invoice-summary--layout .invoice-detail--title {color: #464c50;font-size: 30px;font-weight: 600;}
.single-invoice-summary--layout .invoice-detail-bitly-address--section {padding: 20px 0 20px 0;}
.single-invoice-summary--layout .invoice-detail--list {list-style: none;margin: 0;padding: 0;}
.single-invoice-summary--layout .invoice-detail-bitly-address--section li {color: #464c50;font-weight: bolder;}
.single-invoice-summary--layout .invoice-detail--column-large,.single-invoice-summary--layout .invoice-detail--column-small {color: #464c50;float: left;margin-bottom: 20px;}
.single-invoice-summary--layout .invoice-detail--column-large {width: 80%;}
.single-invoice-summary--layout .invoice-detail--column-small {width: 20%;}
.single-invoice-summary--layout hr {width: 100%;border-color: #aba9a9;display: inline-block;margin: 0;}
.single-invoice-summary--layout .invoice-detail-billing--section {padding: 20px 0 20px 0;}
.single-invoice-summary--layout .invoice-detail-charge-info--section .invoice-detail--column-large,
.single-invoice-summary--layout .invoice-detail-charge-info--section .invoice-detail--column-small {margin: 10px auto 10px auto;}
.single-invoice-summary--layout .invoice-detail--title span.invoice-detail-icon--color img {width: 30px;margin-right: 5px;margin-bottom: 20px;}
.cms-content {margin-top: 70px;margin-bottom:30px;}
.cms-content .text {font-size: 18px;line-height: 30px;margin-bottom: 40px;}
.cms-content .two-columns .image {max-width: 420px;width: 100%;margin: 0 auto;}
.row.two-columns .text span.title {font-size: 25px;font-weight: 600;margin-top: 50px;display: inline-block;margin-bottom: 15px;width: 100%;}
.subscription-pricing--title {width: 75%;margin: 0 auto;}
.subscription-title--container {text-align: center;}
h1.subscription-title--title {text-transform: uppercase;margin-bottom: 25px;margin-top: 25px;}
p.subscription-title--blurb {font-size: 22px;line-height: 28px;}
.subscription-pricing--hidden-desktop {display: none;}
.tier-summary--panel {border: 1px solid #dfdfdf;position: relative;background-color: #fff;border-radius: 10px;overflow: hidden;padding:0 0 60px;z-index:1;}
.tier-summary--panel:after{width:250px;height:250px;content:"";position:absolute;left:-90px;bottom:-220px;background:#dfdfdf;-o-transform: rotate(45deg);-ms-transform: rotate(45deg);-moz-transform: rotate(45deg);-webkit-transform: rotate(45deg);transform: rotate(45deg);z-index:-1;border-radius: 50px;}
.tier-summary--panel .tier-summary-panel--header {min-height:227px;display: inline-block;width: 100%;background: #f5f5f5;color: white;position:relative;z-index:1;margin:0 0 82px;}
.tier-summary--panel .tier-summary-panel--header:after{width:950px;height:630px;content:"";position: absolute;right: -88px;bottom:20px;background:#dfdfdf;border-radius: 50px;-o-transform: rotate(15deg);-ms-transform: rotate(15deg);-moz-transform: rotate(15deg);-webkit-transform: rotate(15deg);transform: rotate(15deg);z-index:-1}
.savedPrcntValue{color:#333;}
.currentPackage{color:#e85629;}
.tier-summary--panel:hover{border-color:#0a6eb4;}
.tier-summary--panel:hover .tier-summary-panel--header,
.tier-summary--panel:hover .tier-summary-panel--header:after,
.tier-summary--panel:hover:after{background:#0a6eb4;}
.tier-summary--panel:hover p.tier-summary--title,
.tier-summary--panel:hover p.tier-summary--price,
.tier-summary--panel:hover .savedPrcntValue,
.tier-summary--panel:hover .currentPackage{color:#fff;}
p.tier-summary--title {text-align: center;margin:20px 0 0;font-size: 30px;text-transform: capitalize;color:#555;}
.withOutDscntPrice{font-size:16px;margin:0 5px 0 0;}
.tier-summary-panel--body {padding: 20px 20px 10px;}

.subscription-pricing--layout
.subscription-pricing--tiers
.subscription-pricing-panel--row
.subscription-pricing-summary--panel.highlight-plan
.highlight-plan--badge {background: #0a6eb4;border-top-left-radius: 5px;border-top-right-radius: 5px;color: #fff;font-size: 16px;height: 35px;letter-spacing: 0.5px;line-height: 35px;margin-top: -35px;position: absolute;text-align: center;width: 100%;}
p.tier-summary--price {font-size: 54px;color:#333;}
.packag-entprz{font-size:45px;}
p.tier-summary--price span.tier-summary-month-descriptor {font-size: 17px;}
p.tier-summary--price span.tier-summary-month-descriptor i {display: block;font-size: 14px;font-style: normal;}
.tier-summary-panel--body p.tier-summary--description {font-size: 14px;line-height: 20px;min-height: 80px;}
.tier-summary-panel--body ul.tier-summary-consumables--list {padding: 0;font-size: 14px;list-style: none;margin:0 0 15px;}
ul.tier-summary-consumables--list li{margin:0 0 15px;}
ul.tier-summary-consumables--list .feature-value{font-size:35px;line-height: 35px;}
ul.tier-summary-consumables--list .feature-name{font-size:20px;line-height: 20px;color:#777;}
ul.singlePackageDetails{height:128px;overflow: hidden;}
ul.singlePackageDetails li{padding:0 0 0 10px;margin:0;}
ul.singlePackageDetails li.section--title{padding:0;margin:0 0 10px}
ul.singlePackageDetails li i{margin: 0 5px 0 0;font-size:12px;}
.button--SOLID-TERTIARY {color: #fff;background-color: #0a6eb4;border: 1px solid #fff;padding: 10px 10px;width: 100%;max-width: 300px;}
.tier-summary-panel--body button {width: 100%;padding: 10px 0;text-transform: uppercase;border-radius: 4px;margin: 0 auto;font-size: 13px;display: table;font-weight: 600;}
.tier-summary-panel--body span.tier-summary-currentPlan {display: block;padding-top: 4px;text-align: center;}
.tier-summary--panel .tier-summary-panel--body a {color: #61b3de;display: block;margin: 8px 0;}
.tier-summary--panel .tier-summary-panel--body a {color: #61b3de;display: block;margin: 8px 0;text-align: center;font-size: 13px;text-transform: uppercase;margin: 0 auto 8px;font-weight: 600;}
.subscription-pricing-comparison-label-column {font-size: 14px;float: left;width: 30% !important;}
.subscription-pricing-comparison-column:nth-child(odd) ul{background-color: #f5f5f5;}
.subscription-pricing-comparison-column {color: #464c50;font-size: 14px;float: left;width: 23% !important;}
.tier-comparison-label--section li {border-bottom: 1px solid #d3d4d7;height: 40px;margin: 0;padding: 10px 0;}
.tier-comparison-label--section .section--title {font-size: 20px;height: 61px;padding-top: 28px;}
.subscription-pricing-comparison-label-column ul {list-style-type: none;margin-left: 0;padding-left: 0;padding-top: 45px;margin-top: 15px;}
.subscription-pricing-comparison-column ul {list-style: none;margin: 0;padding: 20px 0 0 0;margin: 0 0 15px;}
.subscription-pricing-comparison-column .tier-comparison-column--section {border-top: 1px solid #d3d4d7;margin-top: 60px;}
.subscription-pricing-comparison-column .tier-comparison-column--section li {border-bottom: 1px solid #d3d4d7;}
.subscription-pricing-comparison-column ul li {height: 40px;margin: 0;padding: 10px 0;text-align: center;}
.tier-comparison-label--section li:not(.section--title) {font-size: 15px;}
.subscription-pricing-comparison-column .tier-comparison-column--title {font-size: 20px;padding-top: 20px;text-transform: uppercase;}
.subscription-pricing-comparison-column .tier-comparison-column--emdash {color: #d3d4d7;font-weight: bold;}
.button--SOLID-TERTIARY,.subscription-pricing-comparison-column .btn-get-started {width:auto;display:inline-block;vertical-align: top;background:#e85629;padding:8px 15px 6px;color:#fff;font-size:13px;border-radius: 4px;text-transform: uppercase;border:1px solid #e85629;}
.button--SOLID-TERTIARY:hover,.subscription-pricing-comparison-column .btn-get-started:hover {border-color:#e85629;text-decoration:none;background:#fff;color:#e85629 !important;}
.button--SOLID-TERTIARY{padding:10px 15px;}
.button--SOLID-TERTIARY:focus,
.subscription-pricing-comparison-column .btn-get-started:focus,
.admin-section--MAIN button.button--SOLID-PRIMARY:focus,
.customDomainLoaderHolder .button--SOLID-PRIMARY:focus{outline:none !important;}
.subscription-pricing--disclaimer-text {display: inline-block;margin-bottom: 30px;font-size: 15px;width: 100%;margin-top: 20px;text-align: center;}
.subscription-pricing--disclaimer-text p {margin-bottom: 10px;}
.sticky-upgrade--footer .sticky-upgrade--visible {background-color: #fff;visibility: visible;padding: 20px 0;display: inline-block;width: 100%;}
.sticky-upgrade--footer .sticky-upgrade--visible .sticky-upgrade-footer-button-row {display: block;}
.sticky-upgrade--footer .sticky-upgrade--visible .sticky-upgrade-button--column:first-child {width: 30%;}
.sticky-upgrade--footer .sticky-upgrade--visible .sticky-upgrade-button--column {float: left;width: 23%;text-align: center;}
.sticky-upgrade--footer .sticky-upgrade--visible .sticky-upgrade-button--column .button--SOLID-TERTIARY {font-size: 13px;padding: 7px 5px;border-radius: 4px;border: none;text-transform: uppercase;text-align: center;max-width: 200px;display: inline-block;}
.subscription-pricing--tiers div#pills-tabContent nav.nav.nav-tabs {text-align: center;margin: 0 auto;width: 100%;max-width: 480px;background: rgba(235, 236, 238, 0.72);border-radius: 20px;margin-bottom: 50px;}
.pill-toggle {display: inline-block;width: 100%;margin-bottom: 50px;}
.subscription-pricing--tiers div#pills-tabContent nav.nav.nav-tabs .nav-link.active {border-radius: 20px;color: white;background-color: #0a6eb4;}
.subscription-pricing--tiers div#pills-tabContent nav.nav.nav-tabs .nav-link {color: rgba(143, 152, 157, 0.54);width: 50%;}
.tier-comparison-mobile ul .tier-comparison-mobile--item-title {border-bottom: 1px solid #d3d4d7;padding: 10px 0;}
.tier-comparison-mobile ul .tier-comparison-mobile--item-detail:nth-child(even) {background: #f3f8fb;}
.tier-comparison-mobile ul .tier-comparison-mobile--item-detail span:last-child {margin-left: auto;}
.tier-comparison-mobile ul .tier-comparison-mobile--item-detail {display: flex;padding: 10px;}
.tier-comparison-mobile ul {color: #464c50;list-style-type: none;margin-left: 0;padding-left: 0;}
.tier-comparison-mobile .tier-comparison-mobile--section-title {color: #464c50;font-size: 18px;border-bottom: 1px solid #d3d4d7;display: flex;justify-content: space-between;margin: 0;padding: 10px 0;}
.tier-comparison-mobile .tier-comparison-mobile-hide {display: none;}
.tier-comparison-mobile .tier-comparison-mobile-hide.active{display:block;}
span.close-page {float: right;font-size: 20px;margin-top: 8px;}
section.links-list a.nav-link {color: black;border-bottom: 1px solid #b3b3b3;border-radius: 0;padding: 10px 15px 10px 30px;width: 100%;display: inline-block;background: whitesmoke;position: relative;z-index:1}
section.links-list.campaign a.nav-link{padding: 10px 15px;}
section.links-list a.nav-link.active {background-color: white;color: #000;}
section.links-list a.nav-link span.bitlink-item--checkbox {width: 30px;float: left;margin-top: 10px;}
section.links-list a.nav-link time.bitlink-item--created-date {font-size: 12px;color: grey;}
section.links-list a.nav-link .bitlink-item--title {margin-left: 20px;margin-bottom: 0;}
section.links-list a.nav-link .bitlink--MAIN {float: left;margin-left: 30px;font-size: 14px;color: #0a6eb4;}
section.links-list a.nav-link .bitlink--MAIN span.bitlink--hash {font-weight: bold;}
section.links-list a.nav-link span.click-count--MAIN {float: right;color: #0a6eb4;}
section.links-list {padding: 0 15px;}
section.links-list a.nav-link span.click-count--MAIN span.clicks-icon {margin-left: 5px;}
h2.deactivate-account--header {font-size: 20px;}
span.action-sheet--help-text {color: #c7c6c6;font-size: 15px;}
ol.action-sheet--list {padding: 0 15px;}
.radio-button--ALTERNATE label.radio-button--item {display: inline-block;width: 100%;}
section.links-list .bitlink--copy-interface .item--action-buttons button.button--ACTION {font-size: 12px;background: transparent;border: 1px solid #0a6eb4;color: #0a6eb4;padding: 2px 15px;/*margin-left: 5px;*/}
section.links-list .bitlink--copy-interface .item--action-buttons button.button--ACTION:focus{outline:none;}
.bitlink--copyable-text {font-size: 14px;color: #0a6eb4 !important;margin-top: 5px;position: relative;}
section.links-list a.item-detail--url {color: gray;word-wrap: break-word;}
section.links-list .item-detail--title {font-size: 22px;margin-top: 0;}
section.links-list time.item-detail--created-date {font-size: 12px;color: grey;}
body.user-dashboard {height: 100vh;display: flex;display: -webkit-flex;-webkit-flex-flow: column;flex-flow: column;}
section.top-chart {background: #fff;margin-bottom: 0;padding: 0 10px;}
section.links-list .nav.nav-pills {height: 100%;max-height:calc(100vh - 68px);overflow-x: unset;overflow-y: auto;display: inline-block;width: 100%;padding-top:45px;padding-right: 0;padding-bottom: 0;background: lightgray;}
section.links-list .allDataLoaded .nav.nav-pills{padding-bottom:0;}
section.links-list .tab-content .no-content-holder {height: 80vh;display: inline-block;overflow: hidden;overflow-y: auto;padding-top: 0;padding-bottom: 20px;padding: 0 10px 20px 0;}
section.links-list.campaign .tab-content .no-content-holder{height:90vh}
section.links-list .nav.nav-pills .all-info {position:absolute;top:0;padding: 10px 15px;border-bottom: 1px solid lightgray;background:#d3d3d3;z-index:3;color:#0a6eb4;}
section.links-list .nav.nav-pills .all-info{left:0;right:8px;}
section.links-list .infinite-scroll-component .nav.nav-pills .all-info{left:0px;right:8px;}
section.links-list  .infinteScrollParent  .infinite-scroll-component__outerdiv .infinite-scroll-component .nav.nav-pills .all-info{padding:10px 15px;}
section.links-list a.nav-link.active .bitlink-item--title {color: black;}
body.search section.links-list .nav.nav-pills {overflow: unset;overflow-y: unset;display: inline-block;width: 100%;padding-right: 0;padding-bottom: 0;background: whitesmoke;height: auto;}
body.search section.links-list .tab-content {height: auto;display: inline-block;width: 100%;overflow: unset;overflow-y: unset;padding-top: 20px;padding-bottom: 20px;}
.top-info span.title {font-size: 18px;font-weight: 600;}
.filters-bar .filter-pill--ALTERNATE {float: left;margin-right:5px;border:1px solid#0a6eb4;font-size: 14px;color: #0a6eb4;padding:4px 25px 4px 15px;cursor: pointer;border-radius: 4px;background:#fff;position:relative;margin-bottom:10px;}
.filters-bar a.filter-list--clear {cursor: pointer;}
.filters-bar .filter-pill--ALTERNATE .filter-pill--label {float: left;margin-right: 5px;}
.filters-bar .filter-pill--ALTERNATE a.close-icon {color: #0a6eb4;position:absolute;top:5px;right:7px;}
.filters-bar span.filter-list--label {float: left;font-size: 18px;font-weight: bold;margin-right: 10px;}
.filters-bar {padding: 10px 20px 0;background: #d7ecfb;min-height: 82px;}
.filters-bar.campaignsFiltersBar{min-height:52px;}
.filters-bar .filter-list--BLUE {float: left;margin: 3px 0 0 0;}
.filters-bar .filter-list--BLUE .button--MAIN {float: right;background-color: #0a6eb433;color: #0a6eb4;padding: 2px 10px;margin-top: -2px;border-radius: 4px;cursor: pointer;}
.filters-bar .filter-list--BLUE a.filter-icon {margin-right: 15px;}
.checklist--wrapper .checklist--items {margin-top: 5px;}
.checklist--wrapper .search--ALTERNATE input.search--input-field {background: transparent;border: 1px solid white;padding: 10px 15px;color: white;width: 100%;border-radius: 25px;}
.modal-text.account_details .form-group {text-align: left;margin-top: 20px;}
.container-box h1.header-logo a img {max-width: 100%;width:100px;}
header .main-header a.logo img,
.footer-logo {max-width: 100%;width:100px;}
header.user-admin a.logo {width: auto !important;}
div#account_info button.signout {width: 100%;text-align: left;}
.subscription-payment--layout {padding: 45px 0;}
.subscription-payment--layout a {color: #0a6eb4;}
.subscription-payment--layout a.go-back {display: inline-block;padding-left: 15px;position: relative;}
.subscription-payment--layout a.go-back .back_btn-icon {margin-right: 5px;margin-top: 1px;position: absolute;left: 0;top: -1px;}
.subscription-payment--layout form .subscription-payment--left-panel {border-bottom: 0;padding-right: 30px;}
.subscription-payment--layout h3 {font-size: 20px;margin-top: 15px;line-height: 22px;margin-bottom: 15px;font-weight: 600;}
.subscription-payment--layout form .subscription-payment--left-panel .options-plans, .subscription-payment--layout form .subscription-payment--left-panel .options-billing {display: block;padding-bottom: 20px;}

.subscription-payment--layout form .subscription-payment--left-panel .radio-button--item {height: 50px;line-height: 50px;margin-bottom: 5px;}
.subscription-payment--layout form .subscription-payment--left-panel .radio-button--item.active .radio-button--wrapper {background-color: rgb(247, 247, 247);border-radius: 4px;display: inline-block;width: 100%;width: auto;}
.subscription-payment--layout form .subscription-payment--left-panel .radio-button--item .radio-button--wrapper {display: inline-block;padding: 0 16px;}
.subscription-payment--layout .radio-button--button {border: 1px solid #8f989d;border-radius: 100%;display: inline-block;height: 16px;position: relative;vertical-align: middle;width: 16px;}
.subscription-payment--layout .radio-button--input:checked + .radio-button--button:before {background-color: #61b3de;border-radius: 100%;content: ' ';display: block;height: 8px;left: 50%;margin: -4px 0 0 -4px;position: absolute;top: 50%;width: 8px;}
.subscription-payment--layout .radio-button--input {display: none;}
.subscription-payment--layout .radio-button--item .radio-button--wrapper span.radio-button--label {margin-left: 8px;margin-right: 15px;}
.subscription-payment--layout form .subscription-payment--right-panel h3 {margin-bottom: 8px;text-transform: uppercase;}
.subscription-payment--layout form .subscription-payment--right-panel .subscription-payment--description {display: inline-block;line-height: 19px;margin-bottom: 5px;margin-top: 10px;}
.subscription-payment--right-panel h4 {font-size: 15px;font-weight: 600;margin-bottom: 10px;margin-top: 10px;}
.subscription-payment--layout form .subscription-payment--right-panel .subscription-payment--tier-features {list-style-type: none;padding: 0;margin: 0;margin-bottom: 35px;}
.subscription-payment--layout form .subscription-payment--right-panel .subscription-payment--tier-features li {margin-bottom: 10px;}
.subscription-payment--layout form .subscription-payment--right-panel .subscription-payment--tier-features li .checkmark-icon {color: #1564b1;margin-right: 10px;}
.subscription-payment--layout form .subscription-payment--right-panel .subscription-payment--price {font-size: 24px;margin-bottom: 15px;}
.subscription-payment--layout form .subscription-payment--right-panel .subscription-payment--legal {margin-bottom: 20px;}
.subscription-payment--right-panel {padding-left: 30px;background: #f7f7f7;padding-top: 10px;padding-right: 30px;}
.subscription-payment--right-panel button {width: 100%;padding: 10px 0;}
.subscription-payment--legal-checkbox p {float: left;width: calc(100% - 30px);}
.subscription-payment--legal-checkbox div#legal {float: left;width: 25px;margin-right: 5px;}
.subscription-payment--layout form .subscription-payment--left-panel .input-field--MAIN select {padding: 0;height: 26px;}
.sticky-upgrade--footer .sticky-upgrade--visible .sticky-upgrade-button--column .button--SOLID-TERTIARY:hover {color: white;text-decoration: none;}
.dynamic-search-list--MAIN span.react-tagsinput-tag {background-color: #073e65;color: white;border-color: #073e65;}
.react-tagsinput-tag .dynamic-search-list--MAIN {margin-bottom: 10px;}
.dynamic-checklist--MAIN .search--ALTERNATE .radio label input {float: left;width: auto;margin-top: 7px;margin-right: 5px;margin-bottom: 0;}
.react-tagsinput--focused {border-color: #073e65 !important;}
.dynamic-search-list--ALTERNATE .search--ALTERNATE .react-tags__search input {width: 100% !important;}
.react-tags__suggestions ul li * {background: transparent;color: white;cursor: pointer;}
.react-tags__suggestions ul li {background-color: #073e65ed;margin-bottom: 5px;}
.react-tags__suggestions {background-color: #f7f7f7;padding: 10px 10px;}
.react-tags__suggestions ul {list-style: none;padding: 0;margin-top: 5px;margin-bottom: 5px;}
.dynamic-search-list--ALTERNATE .search--ALTERNATE .react-tags__selected button.react-tags__selected-tag {width: auto;padding: 2px 6px;margin-right: 10px;}
button.button--SOLID-SECONDARY.link {background: transparent;color: #02a1e4;border: none;font-size: 15px;padding: 0;width: 100%;margin-top: 10px;}
div#manage_channels .dynamic-checklist--item .input-field--input-wrapper span {position: absolute;right: 8px;top: 11px;}
div#manage_channels .dynamic-checklist--item .input-field--input-wrapper input.input-field--input {margin: 0;padding: 3px 25px 3px 5px;}
.search--ALTERNATE a.create-suggestion {color: #01abf1 !important;cursor: pointer;}
div#manage_channels .dynamic-checklist--item .input-field--wrapper .input-field--ALTERNATE {border-top: 0;margin-bottom: 5px;}
div#create_bitlink .action-sheet--content-wrapper select {width: 100%;margin-bottom: 10px;height: 35px;background: transparent;color: white;border: 1px solid white;border-radius: 4px;}
div#create_bitlink .action-sheet--content-wrapper select option{padding:5px;}
section.links-list .tab-content span.item-detail--back-nav {font-size: 25px;margin-bottom: 5px;display: inline-block;margin-top: 10px;}
.sticky-upgrade--footer	.sticky-upgrade--visible .sticky-upgrade-button--column:first-child .sticky-upgrade-button--column {text-align: right;padding-right: 20px;}
header .menu-outsite span.close-page {color: black;margin-bottom: 6px;}
.hide-desktop {display: none;}
section.top-chart {border-top: 1px solid lightgray;margin-top: -2px;}
span.hidden-links {margin-right: 20px;font-size: 15px;color: black;position: relative;padding:2px 15px 0;display:inline-block;vertical-align:top;}
span.hidden-links input {position: absolute;left: 0;top: 6px;}
.filter-list--FILTER {display: block;width: 100%;}
.text-capital {text-transform: capitalize !important;}
.pointer {cursor: pointer !important;}
.current-plan--text {font-size: 13.5px;padding-top:12px;display:inline-block;vertical-align: top;}
.btn:focus,.btn.focus {outline: 0 !important;box-shadow: none !important;}
.performance-summary-dashboard--MAIN {background-color: #efeeee;padding: 20px 30px 20px;height: 100%;}
.performance-summary-dashboard--MAIN .filter-list--DARK {float: right;}
.performance-summary-dashboard--MAIN h3.performance-summary--title {font-size: 20px;}
.performance-summary-dashboard--MAIN .filter-list--DARK .button--MAIN {float: right;margin-left: 10px;cursor: pointer;background: none;border-radius: 4px;border: none;background-color: rgba(101, 101, 101, 0.2);padding: 5px 10px;}
.performance-summary-dashboard--MAIN .filter-list--DARK .button--MAIN:hover {background-color: #e2e2e2;}
.performance-summary-dashboard--MAIN .performance-summary--total {position: relative;padding: 20px 20px;display: flex;align-items: center;height: 100%;width: 100%;color: #fff;border-radius: 4px;}
.performance-summary--total span.icon {font-size: 100px;padding: 0;color: #ffffff30;text-align: right;line-height: 80px;width: 140px;float: right;}
span.performance-summary--utc-label {font-size: 14px;color: white;background: grey;padding: 3px 5px;border-radius: 2px;}
.performance-summary-dashboard--MAIN .performance-summary--total-clicks {font-size: 40px;line-height: 30px;float: left;color: white;margin:0 10px 0 0;}
.performance-summary-dashboard--MAIN .performance-summary--channels-header {font-size: 18px;color: white;line-height: 30px;}
.performance-summary-dashboard--MAIN .performance-summary--total hr {border-color: #ffffff3d;}
.performance-summary-dashboard--MAIN .top-info {display: inline-block;width: 100%;margin-bottom: 15px;}
.performance-summary-dashboard--MAIN .performance-summary--total .performance-summary--upsell-text {color: #fff;font-size: 12px;}
section.all-features .info-wrapper--DARK {height: 100%;/*padding: 0 20px 0px;background-color: white;*/}
section.all-features .info-wrapper--DARK h4.info-wrapper--header {font-size: 20px;margin-top: 20px;margin-bottom: 20px;float: left;font-weight: 600;}
section.all-features .info-wrapper--DARK .info-wrapper--link {margin-top: 20px;color: #ec5125;font-weight: 600;}
section.all-features .info-wrapper--DARK .empty-state--DARK {width: 100%;height:100%;}
section.all-features .info-wrapper--DARK .bitlink-network--upsell {display: inline-block;width: 100%;text-align: center;padding-top: 50px;}
section.all-features .info-wrapper--DARK .topThreeFloinkHolder{padding: 0 20px 0px;background-color: white;}
.subscription-pricing--tiers div#pills-tabContent nav.nav.nav-tabs .nav-link:hover {border-radius: 20px;}
section.links-list .bitlink--copy-interface .item--action-buttons button.button--ACTION:hover {background: #0a6eb4;color: white;}
span.campaign-title {font-size: 20px;color: black;}
.campaign-channels-list {display: inline-block;width: 100%;margin-top: 10px;}
.campaign-channels-list .item {color: #0a6eb4;float: left;border: 1px solid #0a6eb4;margin-right: 5px;padding: 0 5px;font-size: 15px;line-height: 20px;margin-bottom: 5px;text-align: center;cursor: pointer;border-radius:4px;}
.campaign-channels-list .item:hover,.campaign-channels-list .item.active {background: #0a6eb4;color: white;}
.campaign-channels-list .item.disabled{cursor: not-allowed;}
.campaign-deatils span.title {font-size: 25px;display: inline-block;width: 100%;}
.campaign-deatils span.detail {font-size: 15px;color: gray;display: inline-block;margin-bottom: 20px;}
.campaign-deatils .actions--MAIN .button--ACTION:hover {background: #0a6eb4;color: white;text-decoration: none;}
.campaign-deatils .actions--MAIN .button--ACTION {background: transparent;font-size: 12px;border: 1px solid #0a6eb4;padding: 5px 10px;line-height: 12px;color: #0a6eb4;margin-right: 5px;}
.all-channels {margin-top: 20px;}
.all-channels .channel {margin-bottom: 20px;}
.all-channels span.title {font-size: 22px;}
.all-channels span.deatil {font-size: 14px;color: grey;}
.all-channels .channel .top {display: inline-block;width: 100%;border-bottom: 1px solid #b3b3b3;margin-bottom: 20px;}
.all-channels .channel .top span.title {float: left;width: auto;}
.all-channels .channel .top span.deatil {float: right;line-height: 30px;}
.swal2-popup {max-width: 350px !important;}
.swal2-icon.swal2-success {border-color: hsla(98,55%,69%,.2) !important;display: block !important;}
.successed {background-color: white !important;padding: 7px 0 !important;}
.successed .swal2-title {color: #8dd28d !important;}
.successed button:focus {box-shadow: none;}
.dangered {border: 6px solid #dd3333 !important;background-color: white !important;padding: 7px 0 !important;}
.dangered .swal2-title {color: #dd3333 !important;}
.dangered button:focus,.conformations button:focus {box-shadow: none;}
.dangered button {background: #dd3333 !important;padding: 5px 20px;}
.conformations {background: #a8d3f1 !important;}
.conformations .swal2-title {color: white !important;margin-bottom: 5px !important;}
.swal-footer {text-align: center;}
.action-sheet--wrapper-MAIN .input-field--input-wrapper input:-webkit-autofill {-webkit-text-fill-color: #ffffff !important;caret-color: white !important;}
.input-field--MAIN .input-field--input-wrapper input,
.text-field--MAIN .text-field--textarea-wrapper textarea,
.input-field--MAIN select {background: #ffffff00;border: 1px solid #d3d4d7;color: #ffffff;width: 100%;padding: 5px 5px;border-radius: 4px;}
#notfound {position: relative;height: 100vh;}
#notfound .notfound {position: absolute;left: 50%;top: 50%;-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);}
.notfound {max-width: 410px;width: 100%;text-align: center;}
.notfound .notfound-404 {height: 280px;position: relative;z-index: -1;}
.notfound .notfound-404 h1 {font-family: 'Montserrat', sans-serif;font-size: 230px;margin: 0px;font-weight: 900;position: absolute;left: 50%;-webkit-transform: translateX(-50%);-ms-transform: translateX(-50%);transform: translateX(-50%);background: url('images/bg.jpg') no-repeat;-webkit-background-clip: text;background-clip: text;-webkit-text-fill-color: transparent;background-size: cover;background-position: center;}
.notfound h2 {font-family: 'Montserrat', sans-serif;color: #000;font-size: 24px;font-weight: 700;text-transform: uppercase;margin-top: 0;}
.notfound p {font-family: 'Montserrat', sans-serif;color: #000;font-size: 14px;font-weight: 400;margin-bottom: 20px;margin-top: 0px;}
.notfound a {font-family: 'Montserrat', sans-serif;font-size: 14px;text-decoration: none;text-transform: uppercase;background: #0046d5;display: inline-block;padding: 15px 30px;border-radius: 40px;color: #fff;font-weight: 700;-webkit-box-shadow: 0px 4px 15px -5px #0046d5;box-shadow: 0px 4px 15px -5px #0046d5;}
.action-sheet--content-wrapper::-webkit-scrollbar {width: 0 !important;}
.subscription-payment--layout .input-field--MAIN .input-field--input-wrapper input,
.subscription-payment--layout textarea,
.subscription-payment--layout select {color: black;height: 40px !important;padding: 0 5px !important;}
.tier-summary--panel .tier-summary-panel--body a.button--SOLID-TERTIARY {color: white;text-decoration: none;border-radius: 4px;}
.associated {font-size: 14px;color: grey;}
.associated span.title {font-weight: 600;color: #212529;}
a.checklist-menu-item.cutsom-a {color: #00b1f8 !important;cursor: pointer;}
.associated.light {color: #eee;margin-bottom: 10px;position: relative;border: 1px solid;padding: 2px 5px;}
.associated.light span.title {color: white;}
.associated.light span.cross {position: absolute;right: -8px;top: -8px;background: white;color: black;font-size: 12px;padding: 2px 6px 0;font-weight: bold;border-radius: 50%;cursor: pointer;}
.action-sheet--wrapper-MAIN form .action-sheet--button-wrapper {width:100%;position:absolute;bottom:0;padding:10px 20px;left:0;background:#073e65;height:70px;}
span.clipboard {display: none;width: 170px;background: #0a6eb4;color: white;padding: 5px 10px;margin:0 0 0 5px;opacity: 0;animation-name: fadeInOpacity;animation-iteration-count: 1;animation-timing-function: ease-in;animation-duration: 2s;}
#nav.show span.clipboard {background: white;color: #0a6eb4;}
.reportPageParent .admin-section--header{margin:0 0 10px;font-weight:bold;font-size:25px;line-height:29px;}
.reportPageParent .item-detail--created-date,
.reportPageParent .item-detail--url{font-size: 12px;color: grey;}
.reportPageParent .item-detail--title{font-size:22px;line-height:26px;color:#3a3c3e;}
.reportPageParent .report-details p{margin:0;}
.reportPageParent .detail-heading{color: #000;font-weight:bold;}
.reportPageParent .detail-value{color:#212529;}
.reportPageParent .exportBtn,
.reportPageParent .bottomBtnsHolder .btn{font-size:12px;line-height:14px;padding:8px 20px;border:1px solid #1564b1;border-radius: 2px;color:#1564b1;}
.reportPageParent .exportBtn:hover,.reportPageParent .exportBtn:focus,.reportPageParent .exportBtn:active{border-color:#1564b1;background:#1564b1;color:#fff;}
.checkboxesHolder,
.reportPageParent .bottomBtnsHolder{overflow: hidden;}
.reportPageParent .bottomBtnsHolder .btn{margin: 0 5px 10px 0;padding:10px 25px;}
.reportPageParent .bottomBtnsHolder .btn-search{background:#1564b1;color:#fff;}
.reportPageParent .bottomBtnsHolder .btn-search:hover,.reportPageParent .bottomBtnsHolder .btn-search:active,.reportPageParent .bottomBtnsHolder .btn-search:focus{border-color:#1564b1;background-color:transparent;color:#1564b1;}
.reportPageParent .bottomBtnsHolder .btn-reset,
.reportPageParent .bottomBtnsHolder .btn-reset:active,
.reportPageParent .bottomBtnsHolder .btn-reset:focus{border-color:#e85629;background:#e85629;color:#fff;}
.reportPageParent .bottomBtnsHolder .btn-reset:hover{background-color:transparent;color:#e85629;border-color:#e85629;}
.customCheckBoxLabel {float:left;position: relative;padding-left: 20px;margin: 0 10px 15px 0;cursor: pointer;font-size: 18px;line-height: 18px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.customCheckBoxLabel input {position: absolute;opacity: 0;cursor: pointer;height: 0;width: 0;}
.customCheckBoxLabel .checkmark {position: absolute;top: 0;left: 0;height: 15px;width: 15px;background-color: transparent;border:1px solid #1564b1;}
.customCheckBoxLabel input:checked ~ .checkmark,.customCheckBoxLabel:hover input ~ .checkmark {background-color: #1564b1;border-color:#1564b1;}
.reportPageParent .empty-state--BITLINKS,
.reportPageParent table.reportTable{margin-top:0;}
.reportPageParent table.reportTable{border:solid #1564b1;border-width:1px 0 0 1px;}
.reportPageParent table.reportTable thead{background:#1564b1;color:#fff;}
.reportPageParent table.reportTable th,.reportPageParent table.reportTable td{border:solid #1564b1;border-width:0 1px 1px 0;}
.action-sheet--content-wrapper .btnUpgrade{background:#007bff;border-color:#007bff;color:#fff;border-radius:3px;}
.action-sheet--content-wrapper .btnUpgrade:hover,.action-sheet--content-wrapper .btnUpgrade:active,.action-sheet--content-wrapper .btnUpgrade:focus{border-color:#fff;background:#fff;color:#007bff;}
.btn.btnQRDownload{background:#0a6eb4;border-color:#0a6eb4;color:#fff;}
.btn.btnQRDownload:hover,.btn.btnQRDownload:active,.btn.btnQRDownload:focus{background:#fff;border-color:#0a6eb4;color:#0a6eb4;}
.sidebar-or-divider{padding: 15px 0;position:relative;}
.sidebar-or-divider:after{height:2px;content:"";position:absolute;left:0;top:50%;right:0;margin:-1px 0 0;background:#eee;}
.sidebar-or-divider span{background:#073e65;padding:5px 10px;color:#fff;position:relative;z-index:1;}
@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0.2;
	}
	20% {
		opacity: 0.4;
	}
	30% {
		opacity: 0.6;
	}
	40% {
		opacity: 0.8;
	}
	50% {
		opacity: 1;
	}
	60% {
		opacity: 0.8;
	}
	70% {
		opacity: 0.6;
	}
	80% {
		opacity: 0.4;
	}
	90% {
		opacity: 0.2;
	}
	100% {
		opacity: 0;
	}
}
.connected-account--MAIN span.share-icon {margin-top: 8px;float: right;margin-right: 10px;}
#delete_user .action-sheet--content-wrapper form , #security .action-sheet--content-wrapper form {padding-bottom: 50px;}
#edit_bitlink form {padding-bottom: 60px;}
.text-center.no-found {margin-bottom: 20px;margin-top: 20px;}
button.btn-solid {background: #0a6eb4;border: 1px solid #0a6eb4;color: white;padding: 10px 20px;border-radius: 4px;text-transform: uppercase;}
button.btn-light {background: white;border: 1px solid #0a6eb4;color: #0a6eb4;padding: 10px 20px;border-radius: 4px;text-transform: uppercase;}
button.btn-solid:hover {background: #fff;color: #0a6eb4;}
button.btn-light:hover,
button.btn-light:focus{background: #0a6eb4;color: #fff;outline:none;box-shadow:none;}
.campaign-details {margin-top: 15px;}
.campaign-details span.title {font-size: 22px;}
.actions-button-small .btn-light {padding: 5px 5px;margin-right: 5px;border-radius: 2px;}
.actions-button-small .btn-light:disabled,
.actions-button-small .btn-light:disabled:hover,
.actions-button-small .btn-light:disabled:focus{background:lightgrey;border-color:lightgrey;color:#000;cursor: not-allowed;}
.actions-button-small {font-size: 12px;}
section.links-list a.nav-link span.campaign-title {color: #0a6eb4;padding: 5px 10px;border-radius: 4px;border: 1px solid #0a6eb4;min-width: 70px;display: inline-block;text-align: center;text-transform: capitalize;font-size: 20px;cursor: pointer;}
section.links-list a.nav-link span.campaign-title:hover,section.links-list a.nav-link span.campaign-title.active{color: white;background: #0a6eb4;}
span.float-right.total-clicks {font-size: 15px;padding-top: 10px;padding-right: 30px;}
section.all-features {background: #efeeee;padding: 0 30px 20px;}
.performance-summary-dashboard--MAIN .filter-list--DARK .button--MAIN a {font-size: 14px;}
section.all-features .info-wrapper--DARK + .info-wrapper--DARK {border-right: none;}
section.all-features .info-wrapper--DARK .All-links {display: inline-block;width: 100%;}
.All-links .items {margin-bottom: 20px;border-bottom: 1px solid #e2e2e2;padding-bottom: 5px;position: relative;}
.All-links .items:last-child{border-bottom:none;}
.All-links .items h5 {font-size: 20px;margin-bottom: 2px;color: black;width: 70%;word-wrap: break-word;}
.All-links .items h6 {color: grey;font-size: 14px;margin-bottom: 5px;}
.navbar-nav li.nav-item.colr {margin-right: 5px;}
div#manage_channels .dynamic-checklist--item .input-field--input-wrapper input.input-field--input {border-radius: 4px;border: 1px solid white;padding: 5px 10px;}
.filters-button button.btn.btn-solid {padding: 3px 10px;font-size: 14px;text-transform: capitalize;}
.filters-bar .filter-list--BLUE .date-input {padding: 3px 5px;font-size: 14px;background: #0a6eb4;color: white;border: 1px solid #0a6eb4;border-radius: 4px;}
.filters-bar .filter-list--BLUE .date-input:hover {background: #fff;color: #0a6eb4;}
section.links-list.campaign .nav-link {cursor: auto;}
.campaign-details .actions-button-small button {min-width: 90px;}
.react-tagsinput-input{width: 100% !important;}
.headerUserName{border: 1px solid #0a6eb4;border-radius:4px;color: #0a6eb4;padding: 10px 15px;margin-left: 20px;display:inline-block;vertical-align: top;}
.react-tags__selected{display: none !important;}
.campaign-tags-selected-list {display: inline-block;width: 100%;}
.campaign-tags-selected-list .campaign-tag-item {display: inline-block;margin-right: 5px;padding: 3px 5px;border: 1px solid;background: white;border-radius: 4px;color: #34709e;}
.campaign-tags-selected-list .campaign-tag-item .tag_close{cursor: pointer;}
.campaign-tags-selected-list .campaign-tag-item:hover {background: transparent;color: #fff;}
.campaign-tags-selected-list .campaign-tag-item .tag_name {margin-right: 10px;}
.performance-summary--total.orange{background: #ec5125;}
.performance-summary--total.green{background: #30cc3b;}
.performance-summary--total.blue{background: #0863b4;}
.topDevicesHolder .deviceHolder.turquoise{background:#1abc9c}
.topDevicesHolder .deviceHolder.nephritis{background:#27ae60;}
.topDevicesHolder .deviceHolder.amethyst{background:#9b59b6;}
.topDevicesHolder .deviceHolder.facebook{background:#38529a;}
.topDevicesHolder .deviceHolder.twitter{background:#1c9cea;}
.performance-summary-dashboard--MAIN .performance-summary--total button.performance-summary--upsell-link {background: transparent;border: 1px solid white;font-size: 14px;color: white;border-radius: 2px;}
.All-links .items .bitlink--copy-interface a.bitlink--copyable-text{margin-top: 0;line-height: 24px;}
.All-links .items .date {position: absolute;right: 0;top: 2px;font-size: 13px;color: #878787;}
.float-right.topbar-button {margin-top: 20px;}
.float-right.topbar-button span {font-size: 13px;color: grey;margin-right: 5px;}
.float-right.topbar-button button {background: #ec5125;color: white;border: 1px solid #ec5125;padding: 5px 10px;font-size: 15px;border-radius: 2px;}
.float-right.topbar-button button:hover {background: #fff;color: #ec5125;}
section.all-features .info-wrapper--DARK .bitlink-network--upsell span.sm-network-icon {font-size: 70px;color: lightgray;}
.performance-summary--upsell-wrapper .performance-summary--upsell-text {font-size: 16px;color: grey;}
.left-content{float: left;width: calc(100% - 140px);}
.elementor-price-table__ribbon {position: absolute;top: 0;left: auto;right: 16px;-webkit-transform: rotate(90deg);-ms-transform: rotate(90deg);transform: rotate(90deg);width: 150px;overflow: hidden;height: 150px;}
.elementor-price-table__ribbon .elementor-price-table__ribbon-inner {margin-top: 50px;transform: translateY(-170%) translateX(-56%) translateX(37px) rotate(-45deg);color: #ffffff;font-size: 14px;font-weight: bold;letter-spacing: 1px;background-color: #30cc3b;text-align: center;}
.navbar-light .navbar-nav .nav-item {margin-right: 5px;}
text.highcharts-credits {display: none !important;}
span.filter-list--clear {border: 1px solid red;background: #ecb1b1;color:#000;padding: 5px 5px 3px;border-radius: 2px;font-size: 14px;display:inline-block;vertical-align:top;-o-transition: all ease-in-out 0.2s;-ms-transition: all ease-in-out 0.2s;-moz-transition: all ease-in-out 0.2s;-webkit-transition: all ease-in-out 0.2s;transition: all ease-in-out 0.2s;margin-bottom:10px;}
span.filter-list--clear:hover{background:#ee4d4d;color:#fff;}
.top-bar-bk {background: #0a6eb4;color: white;}
.media-container-column .title h1 {font-size: 45px;}
.media-container-column .title h1 small {font-size: 20px;}
.elementor-price-table__ribbon.popular {right: 16px;transform: rotate(90deg);height: 235px;width: 235px;}
.elementor-price-table__ribbon.popular .elementor-price-table__ribbon-inner {background-color: orange;transform: translateY(33%) translateX(-40%) translateX(25px) rotate(-46deg);}
.activeCheckBox {background-color: #0b6eb4;}
section.links-list .link-checkBox{padding: 0px 7px;margin-right: 3px;margin-left: 0;border: 1px solid black;height: 15px;top: 40px;left: 20px;z-index:2;}
.total-selected-main{text-align: center;margin-top: 30px;}
.total-selected-main .selected-links{font-size: 25px;}
.total-selected-main .clicks{font-size: 15px;}
.total-selected-main .item--action-buttons {margin-top: 10px;}
.total-selected-main .item--action-buttons .btn-solid {padding: 5px 20px;}
.elementor-price-table__ribbon.popular .elementor-price-table__ribbon-inner {background-color: orange;transform: translateY(33%) translateX(-40%) translateX(25px) rotate(-46deg);}
header .menu-outsite .create a#navbarcreate:after{margin-left: 10px;}
span.hidden-links {margin-right: 0;}
.filter-list--FILTER .date-input,
.btn-refresh-floinks{padding: 4px 5px;font-size: 14px;background: #0a6eb4;color: white;border: 1px solid #0a6eb4;border-radius: 4px;}
.filter-list--FILTER .date-input:hover,
.btn-refresh-floinks:hover {background: #fff;color: #0a6eb4;}
.btn-refresh-floinks{float:right;}
.btn-refresh-floinks i{margin-right:5px;}
.highcharts-container{margin:0 auto;}
.highcharts-container svg{padding: 0 20px;}
.nav-item.colr a{color: #fff !important;background-color: transparent;border: 1px solid #0a6eb4;border-radius: 4px;}
.nav-item.colr a.btn-orange{background-color: #e85629;color: white;border-radius: 4px;border: 1px solid #e85629;}
.navbar-nav li.nav-item.colr a{color:#0a6eb4 !important;}
.navbar-nav li.nav-item.colr a:hover{color:#fff !important;}
.item--action-buttons .btn{margin:0 4px 8px;}
.createTagsInput{width:100%;height:35px;padding:5px;margin:0 0 5px;}
.createTagButton{display:inline-block;vertical-align: top;padding:5px 10px;background:#fff;color:#000;text-align:center;margin:0 0 0 1px;cursor: pointer;}
.tagsHolder{margin-top:5px;background:#fff;}
.tagsHolder .addedTag{display:inline-block;vertical-align: top;font-size:13px;border:1px solid #073e65;background:#073e65;border-radius:2px;padding:5px;margin:3px;}
.tagsHolder .addedTag .cross-icon{margin:0 0 0 3px;cursor: pointer;}
/* Swal Reset Styles */
.swal2-popup{max-width: 500px !important;}
.swal2-popup.swal2-modal.dangered{border:none !important;}
.swal2-title{font-size:35px !important;}
.swal2-actions{margin:1.25em auto 1em !important;}
.swal2-actions .swal2-styled{min-width: 90px;min-height:40px;}
.coming-soon-block{flex: 0 0 100%;max-width: 100%;height:auto;min-height:calc(100vh - 384px);}
.coming-soon-block img{width:100%;height: 100%;object-fit: cover;}
.text-field--MAIN .text-field--textarea-wrapper textarea{min-height:150px;resize:none;}
.sticky-upgrade--footer .sticky-upgrade--visible .sticky-upgrade-button--column .button--SOLID-TERTIARY{width:auto;padding:7px 15px;display:inline-block;vertical-align: top;}
.elementor-price-table__ribbon.popular .elementor-price-table__ribbon-inner{transform:translateY(-89%) translateX(-39%) translateX(20px) rotate(-45deg)}
.floink-content-section{margin:0 0 70px;}
.floink-table{border:solid #3cafff;border-width:1px 0 0 1px;}
.table.floink-table thead th{border-bottom-width: 1px;}
.floink-table thead{background:#0a6eb4;color:#fff;}
.floink-table th,
.floink-table td{border:solid #0a6eb4	;border-width:0 1px 1px 0;}
/* FAQs Styles */
.floink-faqs .card{border-radius: 0;border-color:#0a6eb4 #0a6eb4 #fff;margin:0 0 1px;}
.floink-faqs .card:last-child{border-bottom-color:#0a6eb4}
.floink-faqs .card-header{background:#0a6eb4;padding:0;}
.floink-faqs .card-header button{display: block;padding:10px 15px;color:#fff;border-radius: 0;width: 100%;text-align: left;}
.floink-faqs .card-header button:hover,.floink-faqs .card-header button:focus{text-decoration: none;}
.floink-faqs .floink-table{max-width:500px;min-width:450px;}
.leftButtonsHolder .btn{margin:0 10px 10px 0;}
.centerButtonsHolder .btn{margin: 0 5px 10px;}
.row.first-row{margin-bottom: 50px;}
.row.second-row .heading-block{margin-bottom: 0 0 30px;}
.special-feature-holder{min-height:180px;border: 1px solid #fbfdff;background: #fbfdff;padding: 15px;border-radius: 7px;box-shadow: 0 0 2px rgba(0,0,0,0.1);}
.solution-section{padding:70px 0;}
.solution-section .heading-block h2{font-weight:600;color:#0a6eb4;font-size:34px;line-height:32px;}
.solution-section .heading-block h4{font-weight:500;color:#e85629;}
.solution-section .heading-block p{margin:0 0 15px;}
.single-feature-holder,
.single-feature-holder .feature-text-holder{overflow: hidden;}
.single-feature-holder .feature-image-holder{float:left;width:28%;margin:0 2% 15px 0;padding:4px;}
/* .solution-section.bg-grey .single-feature-holder .feature-image-holder{float: right;margin:0 0 15px 2%;} */
.single-feature-holder .feature-image-holder .image-holder{border:4px solid #fff;border-radius: 4px;box-shadow:0 0 4px rgba(0,0,0,0.2);overflow: hidden;}
.content-article{display:inline-block;vertical-align:top;background: #fbfdff;padding: 8px;font-size:14px;margin:0 0 10px;}
.content-article h3{font-size:16px;}
.content-article p{margin:0;}
.solution-section.bg-grey{background:#fbfbfb;}
.addedTagsHoler{padding: 5px 0;}
.addedTagsHoler > span,
span.performance-summary--utc-label{background-color: #073e65;color: white;border-color: #073e65;margin-bottom: 5px;margin-right: 5px;padding: 6px 8px;display:inline-block;vertical-align:top;}
.floin-content-o-points li{margin: 0 0 5px;}
.qrcode-block{display: flex;}
.qrcode-block .qrcode-holder{min-width:480px;margin:auto;background:#fff;border-radius:4px;padding:40px 20px;box-shadow: 0 0 7px 0px rgba(0, 0, 0, 0.09);}
.qrcode-block .qrcode-holder p{margin: 0 0 10px;}
.qrcode-block .qr-code-holder{margin:0 0 15px;}
.performance-summary-dashboard--MAIN .date-picker-input{background:none;height:38px;border:2px solid #007bff;border-radius:4px;color:#007bff;-o-transition: all ease-in-out 0.15s;-ms-transition: all ease-in-out 0.15s;-moz-transition: all ease-in-out 0.15s;-webkit-transition: all ease-in-out 0.15s;transition: all ease-in-out 0.15s;}
.performance-summary-dashboard--MAIN .date-picker-input:hover{background:#007bff;color:#fff;}
.no-link-found,
.dsbhrd-left-content-holder{height:calc(100% - 64px);}
.info-wrapper--heading-holder{overflow: hidden;}
/* section.all-features .info-wrapper--DARK{height:630px;} */
section.all-features .info-wrapper--DARK .All-links{height:calc(100% - 64px);}
.no-record-found{min-height:calc(100% - 80px);}
.select-item--MAIN-b{position: relative;padding: 5px 21px 5px 5px;}
span.checklist-row--icon{position: absolute;top: 10px;right: 5px;margin:0;}
span.email-unverified {display: inline-block;vertical-align: middle;}
section.all-features .info-wrapper--DARK .bitlink-network--upsell{height:calc(100% - 64px);}
.no-link-found,
.no-record-found,
.empty-state--DARK,
section.links-list .tab-content .no-content-holder,
section.all-features .info-wrapper--DARK .bitlink-network--upsell{display:flex;justify-content: center;align-items: center;}
.admin-section--top .admin-section--item{padding: 0;border:none;}
.admin-section--top .admin-section--item p.marketing-module--text {padding:0 120px 0 0;position: relative;border:1px solid #0a6eb4;}
.admin-section--top .admin-section--item p.marketing-module--text input{width:100%;height:36px;border:none;padding:5px 10px;}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {transition: background-color 5000s ease-in-out 0s;}
.admin-section--top .admin-section--item p.marketing-module--text .admin-section--cta{position: absolute;top:0;right:0;}
.admin-section--top .admin-section--item p.marketing-module--text .admin-section--cta button{padding:5px 15px;border-radius: 0;}
span.performance-summary--utc-label {display:inline-block;vertical-align: middle;}
.performance-summary-dashboard--MAIN .filter-list--DARK .button--MAIN{background:none;padding:0;}
.react-bootstrap-daterangepicker-container input,
.performance-summary-dashboard--MAIN .filter-list--DARK .button--MAIN{background-color:#0a6eb4;color:#fff;border: 1px solid #0a6eb4;border-radius: 3px;padding:3px 10px 2px;}
.react-bootstrap-daterangepicker-container input:hover,
.performance-summary-dashboard--MAIN .filter-list--DARK .button--MAIN:hover{background: none;color:#0a6eb4;border-color:#0a6eb4;}
.mbr-form textarea{resize: none;}
.info-wrapper--header-holder:after{content:"";clear:both;display:block;}
.performance-summary-dashboard--MAIN .float-right .react-bootstrap-daterangepicker-container{display:block !important;}
.floink-pricing-block,
.subscription-pricing-block,
#uncontrolled-tab-example-tabpane-payAnnualy,
#uncontrolled-tab-example-tabpane-payMonthly{overflow-x: auto;padding:0 0 15px;}
.floink-pricing-row,
.subscription-pricing-row,
.subscription-pricing-panel--row.row{display: flex;flex-direction: row;flex-wrap: nowrap;}
.floink-pricing-row,
.subscription-pricing-row{position:relative;}
.floink-pricing-row .floink-pricing-col,
.subscription-pricing-col{flex: 0 0 25%;}
.floink-pricing-row .floink-pricing-col:first-child,
.subscription-pricing-comparison-label-column:first-child{position: sticky;top: 0;left: 0;background: #fff;z-index: 1;background: #f1f9ff;padding:0 0 0 10px;}
/* horizental scroll */
/* .floink-pricing-block::-webkit-scrollbar,
#uncontrolled-tab-example-tabpane-payAnnualy::-webkit-scrollbar,
#uncontrolled-tab-example-tabpane-payMonthly::-webkit-scrollbar,
.subscription-pricing-block::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar{height:8px;}
.floink-pricing-block::-webkit-scrollbar-track,
#uncontrolled-tab-example-tabpane-payAnnualy::-webkit-scrollbar-track,
#uncontrolled-tab-example-tabpane-payMonthly::-webkit-scrollbar-track,
.subscription-pricing-block::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track{box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);}
.floink-pricing-block::-webkit-scrollbar-thumb,
#uncontrolled-tab-example-tabpane-payAnnualy::-webkit-scrollbar-thumb,
#uncontrolled-tab-example-tabpane-payMonthly::-webkit-scrollbar-thumb,
.subscription-pricing-block::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb{background-color: #0a6eb4;outline: 1px solid slategrey;} */
/* vertical scroll */
/* body::-webkit-scrollbar,
.dsbhrd-right-content-holder::-webkit-scrollbar,
section.links-list .nav.nav-pills::-webkit-scrollbar,
.customDomainLoaderHolder .infinite-scroll-component__outerdiv::-webkit-scrollbar,
#loginHstoryScrollParent::-webkit-scrollbar,
.css-4ljt47-MenuList::-webkit-scrollbar,
.css-11unzgr::-webkit-scrollbar{width:8px;}
body::-webkit-scrollbar-track,
.dsbhrd-right-content-holder::-webkit-scrollbar-track,
section.links-list .nav.nav-pills::-webkit-scrollbar-track,
.customDomainLoaderHolder .infinite-scroll-component__outerdiv::-webkit-scrollbar-track,
#loginHstoryScrollParent::-webkit-scrollbar-track,
.css-4ljt47-MenuList::-webkit-scrollbar,
.css-11unzgr::-webkit-scrollbar{background:rgb(230, 230, 230);}
body::-webkit-scrollbar-thumb,
.dsbhrd-right-content-holder::-webkit-scrollbar-thumb,
section.links-list .nav.nav-pills::-webkit-scrollbar-thumb,
.customDomainLoaderHolder .infinite-scroll-component__outerdiv::-webkit-scrollbar-thumb,
#loginHstoryScrollParent::-webkit-scrollbar-thumb,
.css-4ljt47-MenuList::-webkit-scrollbar-thumb,
.css-11unzgr::-webkit-scrollbar-thumb{background-color: #0a6eb4;outline: 1px solid slategrey;} */





/* The emerging W3C standard
   that is currently Firefox-only */
   * {
	scrollbar-width: thin;
	scrollbar-color: #0a6eb4 #ccc;
  }
  
  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
	width: 8px;
	height:8px;
	border:none !important;
  }
  *::-webkit-scrollbar-track {
	background: #ccc;
	border:none !important;
  }
  *::-webkit-scrollbar-thumb {
	background-color: #0a6eb4;
	border:none ;
  }






.dsbhrd-right-content-holder:root, .scroller, .infinite-scroll-component {scrollbar-color: rgb(10, 110, 180) rgb(230, 230, 230) !important;scrollbar-width: thin !important;}
.subscription-pricing-summary--panel{margin:0 0 30px;}
.showOnMobile{display: none;}
.empty-state--BITLINKS .avlbl-domain-row,
.resulted-domains .resulted-domain{padding:20px 15px;overflow: hidden;background:#f8fcffed;}
.empty-state--BITLINKS .avlbl-domain-row:nth-child(even),
.resulted-domains .resulted-domain:nth-child(even){background:#f5f5f5;}
.marketing-module--upsell-text{padding:7px;display:inline-block;vertical-align: top;}
.resulted-domains .resulted-domain-name{font-size:24px;font-weight:500;color:#212529;}
.dsbhrd-left-content-holder,
.dsbhrd-right-content-holder{overflow-y: auto;}
.dsbhrd-right-content-holder{height:calc(100vh - 61px);}
.action-sheet--content-wrapper button{width:100%;}
#nav.show .bitlink--copy-interface a.bitlink--copyable-text{float:none;}
.modal-header{display:block !important; text-align: right;}
.modal-header .close{float:none;}
.account-type-name{float:left;margin:0 5px 0 0;}
.account-type-value{overflow: hidden;word-break: break-all;display: block;}
.complete-purchase-loader .lds-ring div {border-color: #0a6eb4 transparent transparent transparent;}
.action-sheet--list-content-wrapper .lds-ring{position: absolute;left: 50%;margin: 0 0 0 -15px;}
.qrcode-block{min-height:calc(100vh - 518px);}
.customDomainLoaderHolder{padding:0 0 75px;}
.customDomainLoaderHolder .css-sgiz3{top:auto;bottom: 0;}
.complete-purchase-btn .lds-ring{float:left;margin:-3px 5px 0;}
.complete-purchase-btn .purchase-btn-text{padding: 2px 0 0;display: inline-block;vertical-align: top;}
._loading_overlay_overlay,
._loading_overlay_content{position: fixed !important;top: 0 !important;right: 0 !important;bottom: 0 !important;left: 0 !important;background:#0a6eb4;}
._loading_overlay_spinner{position: absolute !important;margin: -25px 0 0 -25px !important;width: 50px;max-height: 100%;left: 50%;top: 50%;}
.main-loader{background-color: white;position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 1;}
/* loader by uzair */
.lds-ring {display: inline-block;position: relative;width: 30px;height: 30px;}
.lds-ring div {box-sizing: border-box;display: block;position: absolute;width: 25px;height: 25px;margin: 5px 0 0 ;border: 2px solid #fff;border-radius: 50%;animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;border-color: #fff transparent transparent transparent;}
.lds-ring div:nth-child(1) {animation-delay: -0.45s;}
.lds-ring div:nth-child(2) {animation-delay: -0.3s;}
.lds-ring div:nth-child(3) {animation-delay: -0.15s;}
.form-inline .form-control{padding:6px 40px 6px 12px;}
.navbar-light .navbar-toggler:focus{outline:none;}
.col-md-4.infinteScrollParent{padding:0;}
.infinteScrollParent .infinite-scroll-component{padding-bottom:70px;position: relative;overflow: hidden !important;}
.infinteScrollParent.allDataLoaded .infinite-scroll-component{padding-bottom:0;}
.infinteScrollParent .css-dl8xky,
.infinteScrollParent .css-1dru0rn,
.infinteScrollParent .css-sgiz3{position:absolute;left:50%;bottom:7px;margin:0 0 0 -25px;}
.infinteScrollParent .css-1dru0rn,
.infinteScrollParent .css-sgiz3{bottom:10px;top:auto;}
.infinite-scroll-component__outerdiv{/*max-height:calc(100vh - 61px);*/height:100%;}
#loginHstoryScrollParent .infinite-scroll-component__outerdiv{max-height:350px;}
#loginHstoryScrollParent.allDataLoaded .infinite-scroll-component {padding-bottom:0;}
.customDomainLoaderHolder .infinite-scroll-component__outerdiv{overflow-y: auto;}
.infinite-scroll-component {height:100% !important; background: lightgray;}
#loginHstoryScrollParent{overflow-y: auto;}
#loginHstoryScrollParent .infinite-scroll-component {padding:0 0 70px;position:relative;}
#loginHstoryScrollParent .css-1dru0rn{position:absolute;left:50%;bottom:10px;-o-transform: translateX(-50%);-ms-transform: translateX(-50%);-moz-transform: translateX(-50%);-webkit-transform: translateX(-50%);transform: translateX(-50%);}
#loginHstoryScrollParent .infinite-scroll-component{background:none;}
.section.links-list .infinteScrollParent .nav.nav-pills{max-height:100%;}
.more-less-btn{margin:0 0 8px;display:block;}
.more-less-btn span{font-size:12px;color:#0a6eb4;}
.tier-summary-panel--body.active .more-less-btn .show-more-text,
.more-less-btn .show-less-text{display:none;}
.tier-summary-panel--body.active .more-less-btn .show-less-text{display:block;}
.tier-summary-panel--body.active ul.singlePackageDetails{height: auto;}
.manageAppWrapper .input-field--ALTERNATE,
.manageAppHolder input{margin-bottom:0 !important;}
.manageAppWrapper .input-field--ALTERNATE{border-bottom:1px solid #35637c;}
.manageAppHolder{padding:0 10px 0 0;}
.manageAppHolder span.icon-cross{position:absolute;top:50%;right:5px;margin:-11px 0 0;cursor: pointer;}
.primaryEmailId{text-decoration: underline;line-height:15px;}
.btn-paypal-holder{max-width:80%;padding:7px 0 0;}
.btn-paypal{padding:0;display:inline-block;vertical-align:top;border:none;}
.btn-paypal:focus,.btn-paypal:active{outline:none;}
.or-divider{position: relative;}
.or-divider:after{width:100%;height:1px;content:"";position:absolute;left:0;top:13px;background:#ccc;}
.or-divider span{padding:5px;font-size:14px;position: relative;z-index: 1;background:#fff;}
.paymentMessageParent{height:calc(100vh - 61px);justify-content: center;align-items: center;background: #d7ecfb;}
.paymentMessageParent .messageBlock,
.paymentMessageParent .EmailMessageHolder{border-radius:15px;background: #fff;padding:25px 15px;box-shadow: 0 6px 10px rgba(0,0,0,0.2)}
.paymentMessageParent .EmailMessageHolder{padding:35px 15px;}
.paymentMessageParent .messageBlock{min-width:390px;}
.paymentMessageParent .messageBlock h2{font-weight:900;}
.paymentMessageParent .EmailMessageHolder h2,
.paymentMessageParent .EmailMessageHolder h3{font-weight:600;}
.paymentMessageParent .icon-holder{padding:30px 15px;font-size:100px;line-height:50px;}
.paymentMessageParent.error .icon-holder{color:#e85629;}
.paymentMessageParent.success .icon-holder{color:#77d79a;}
.paymentMessageParent .text-holder{background:#fff;padding:15px 10px;color:#000;position: relative;font-size:20px;font-weight:600;}
.paymentMessageParent .text-holder p{margin:0 0 25px;}
.paymentMessageParent .btn-paymentMessage{display:block;background:#fff;border:2px solid transparent;color:#fff;padding:7px 20px;overflow: hidden;}
.paymentMessageParent.error .btn-paymentMessage,
.paymentMessageParent.error .btn-paymentMessage:hover{background:#e85629;}
.paymentMessageParent.success .btn-paymentMessage{background:#77d79a;}
.paymentMessageParent .btn-paymentMessage:hover{text-decoration: none;}
.paymentMessageParent.error .btn-paymentMessage:hover{border-color:#e85629;color:#e85629;background:#fff;}
.paymentMessageParent.success .btn-paymentMessage:hover{border-color:#77d79a;color:#77d79a;background:#fff;}
.EmailMessageHolder .icon-holder .email-icon{width:150px;height: auto;}
button.btn-deleteNumber{margin-top:15px;background:#e85629;border-color:#e85629;color:#fff;}
button.btn-deleteNumber:hover,button.btn-deleteNumber:focus{background:#fff !important;border-color:#fff;color:#e85629 !important;outline:none !important;}
.short-details-holder {min-height: 210px;display: table;text-align: center;width: 100%;}
.short-details-holder ul{display:table-cell;vertical-align: middle;}
.EmailMessageHolder .icon-holder .email-icon{width:150px;}
.EmailMessageHolder .footerBtns .btn{border-radius: 4px;}
.filter-tobbar-holder{display:block;overflow: hidden;margin:0 0 5px;}
.filter-list--FILTER .filter-tobbar-holder:last-child{margin:0;}
.refresh-icon{display:inline-block;vertical-align:top;padding:5px 0 0;}
.graph-loader .css-1dru0rn{margin-top:25%;}
.addFloInkAddChannelList .checklist--items {border-bottom:1px solid #35637c;padding:10px 0;position:relative;}
.customDomainPageLoader {background:#0a6eb4;}
.customDomainPageLoader .css-oqei6{position: absolute;left:50%;top:50%;}
/*Static Page Styles */
.card.bgLightBlue,
.bgLightBlue{background-color: #f8fafc;}
.staticPageHeader{min-height:340px;display:flex;text-align: center;align-items: center;background:url(./images/page-header.jpg);background-size: cover;background-repeat: no-repeat;}
.staticPageHeader h1{font-size:60px;line-height: 65px;margin:0;font-family: 'Proxima', Arial, Helvetica, sans-serif;font-weight:900;}
.sectionHeadingBlock{padding:125px 0 75px;position: relative;}
.sectionHeadingBlock.lessBottomPadding{padding:125px 0 25px;}
.sectionHeadingBlock:after{width:2px;height:115px;content:"";position:absolute;left:50%;top:0;margin:0 0 0 -1px;background:#0a6eb4;}
.sectionHeadingBlock h2,
.sectionIntroText h2,
.headingSectionStyle-02 h2{font-size:36px;line-height: 40px;margin:0 0 10px;font-family: 'Proxima', Arial, Helvetica, sans-serif;font-weight:bold;color:#3a3c3e;}
.sectionHeadingBlock small{font-size:22px;line-height: 26px;font-family: 'Proxima', Arial, Helvetica, sans-serif;color:#e85629;}
.faqsBlock{padding:0 0 50px;}
.faqsBlock .card,
.contentArticlesHolder .contentArticle{margin:0 0 15px;border:solid #0a6eb4;border-width:0 0 0 6px;border-radius:4px;overflow: hidden;padding:25px 20px;}
.contentSection.bgLightBlue .contentArticlesHolder .contentArticle{background: #fff;}
.faqsBlock .card-header{background:none;padding:0;border:none;margin:0;}
.faqsBlock .card-header h2{line-height: 0.1;}
.faqsBlock .card-header .btn-link{width:100%;text-align:left;font-size:18px;line-height: 22px;margin:0;padding:0 30px 0 0;font-family: 'Proxima', Arial, Helvetica, sans-serif;font-weight:bold;color:#3a3c3e;position: relative;}
.faqsBlock .card-header .btn-link:hover,.faqsBlock .card-header .btn-link:focus{text-decoration: none;}
.faqsBlock .card-header .btn-link[aria-expanded="true"]{color:#1567b5;}
.faqsBlock .btn-link .toggleIcon{width:27px;height:27px;border:2px solid #3a3c3e;border-radius:100%;position: absolute;top:-2px;right:0;}
.faqsBlock .btn-link .toggleIcon:after{content:"\f067";font-size:15px;color:#3a3c3e;font-family:"Font Awesome 5 Free";text-align:center;}
.faqsBlock .card-header .btn-link[aria-expanded="true"] .toggleIcon{border-color:#1567b6;color:#1567b6;}
.faqsBlock .card-header .btn-link[aria-expanded="true"] .toggleIcon:after{content:"\f068";}
.faqsBlock .blankSpacer{height:20px;}
.faqsBlock .card-body{background:#fff;font-size:18px;line-height:22px;font-family: 'Proxima', Arial, Helvetica, sans-serif;color:#3a3c3e;}
.faqsBlock .card-body p:last-child{margin:0;}
.sectionIntroText{font-size:18px;line-height:22px;margin:0 0 50px;}
.sectionIntroText h2{position: relative;padding:0 0 5px;}
.sectionIntroText h2:after,
.contentArticlesHolder .contentArticle h3:after{width:40px;height:3px;content:"";position: absolute;left: 0;bottom: 0;background:#1567b5;}
.contentArticlesHolder{overflow: hidden;padding:0 0 60px;}
.contentArticlesHolder .contentArticle{margin:0 0 15px;font-size:18px;line-height:22px;}
.contentArticlesHolder .contentArticle h3{font-size:24px;line-height:28px;margin:0 0 10px;font-family: 'Proxima', Arial, Helvetica, sans-serif;font-weight:bold;color:#3a3c3e;padding:0 0 10px;position:relative;}
.contentArticlesHolder .contentArticle h3:after{width:60px;}
.centerBtnsHolder{padding:40px 0 0;}
.centerBtnsHolder .btn{margin:0 5px 10px 5px;}
.solutionTextBlock{padding:100px 0;font-size:18px;line-height:22px;}
.headingSectionStyle-02{position:relative;font-size:22px;line-height: 26px;color:#e85629;padding:0 0 13px;margin:0 0 40px;}
.headingSectionStyle-02 h2{margin:0 0 3px;}
.headingSectionStyle-02 p{margin:0;}
.headingSectionStyle-02:after{width:40px;height:3px;content:"";position:absolute;left:0;bottom:0;background:#1567b5;}
.solutionTextBlock h3{font-size:24px;line-height: 28px;margin:0 0 15px;font-family: 'Proxima', Arial, Helvetica, sans-serif;font-weight:bold;color:#3a3c3e;}
.solutionDetailList li{margin:0 0 17px;}
.solutionDetailList li strong{color:#e85629;}
.solutionTextBlock  .solutionImageHolder{border:1px solid #e3e3e3;border-radius:4px;box-shadow:2px 3px 5px rgba(0,0,0,0.12);}
.solutionTextBlock .centerButtonsHolder{padding:35px 0 0;}
.btn.btn-cb,.btn.btn-cw{font-size:18px;line-height:22px;padding:12px 30px;}
.bottomShadow{box-shadow:2px 2px 9px rgba(0,0,0,0.12);position:relative;}
.solutionDetailsRow{padding:50px 0 0;}
.solutionDetailsBlock{padding:0 0 110px;}
.solutionDetailsBlock .solutionDetailBlock{height:100%;position: relative;padding:87px 15px 15px;font-size:18px;line-height:26px;color:#3a3c3e;margin:0 0 30px;}
.solutionDetailBlock .iconHolder{width:107px;height:107px;display:flex;justify-content:center;align-content:center;background: #fff;border-radius: 100%;box-shadow: 0 0 5px rgba(0,0,0,0.12);overflow: hidden;position: absolute;left: 50%;top:0;-o-transform: translate(-50%,-50%);-ms-transform: translate(-50%,-50%);-moz-transform: translate(-50%,-50%);-webkit-transform: translate(-50%,-50%);transform: translate(-50%,-50%);}
.solutionDetailBlock .iconHolder img{width:50px;}
.solutionDetailsBlock h3{font-size:22px;line-height: 26px;margin:0 0 15px;padding:0 0 15px;font-family: 'Proxima', Arial, Helvetica, sans-serif;font-weight:bold;color:#3a3c3e;position:relative;}
.solutionDetailsBlock h3:after{width:60px;height:3px;content:"";position:absolute;bottom:0;left:50%;margin:0 0 0 -30px;background:#0a6eb4;}
.noChannelHistoryDataOuter{padding:15px 0;}
.noChannelHistoryDataInner{height:150px;background:#ccc;}
.noChannelHistoryDataInner p{margin:0;}
.channelSelectDropdown > div,
.select-item--MAIN > div,
.select-item--MAIN select{cursor: pointer;}
.moreAddedEmailIds,
.allAddedChannels{max-height:400px;overflow-y: auto;margin:0 0 15px;}
.moreAddedEmailIds{padding:5px 0 0;}
.select-item--MAIN .dropdown.bootstrap-select,
.input-field--MAIN .dropdown.bootstrap-select,
.edit_billing_info .dropdown.bootstrap-select{width:100% !important;margin:0 0 10px}
.edit_billing_info .dropdown.bootstrap-select{padding:0;}
.select-item--MAIN .dropdown.bootstrap-select button,
.edit_billing_info .dropdown.bootstrap-select button{padding:5px 10px;margin:0;color:#073e65;background:none;width:100%;max-width: 100%;text-transform:none;}
.select-item--MAIN .dropdown.bootstrap-select button:hover,
.select-item--MAIN .dropdown.bootstrap-select.show .btn-light.dropdown-toggle,
.select-item--MAIN .dropdown.bootstrap-select button:active,
.select-item--MAIN .dropdown.bootstrap-select button:focus,
.edit_billing_info .dropdown.bootstrap-select button:hover,
.edit_billing_info .dropdown.bootstrap-select button:active,
.edit_billing_info .dropdown.bootstrap-select button:focus,
.edit_billing_info .dropdown.bootstrap-select.show .btn-light.dropdown-toggle{background:#073e65;color:#fff;outline:none !important;}
.select-item--MAIN .dropdown.bootstrap-select .dropdown-menu,
.edit_billing_info .dropdown.bootstrap-select .dropdown-menu{padding:0;border-radius:0;border:1px solid #fff;}
.select-item--MAIN .dropdown.bootstrap-select .dropdown-item{color:#073e65;}
.select-item--MAIN .dropdown.bootstrap-select li.selected.active .dropdown-item,
.select-item--MAIN .dropdown.bootstrap-select .dropdown-item:hover,
.select-item--MAIN .dropdown.bootstrap-select .dropdown-item:active,
.select-item--MAIN .dropdown.bootstrap-select .dropdown-item.active,
.select-item--MAIN .dropdown.bootstrap-select .dropdown-item:focus,
.edit_billing_info .dropdown.bootstrap-select li.selected.active .dropdown-item,
.edit_billing_info .dropdown.bootstrap-select .dropdown-item:hover,
.edit_billing_info .dropdown.bootstrap-select .dropdown-item:active,
.edit_billing_info .dropdown.bootstrap-select .dropdown-item.active,
.edit_billing_info .dropdown.bootstrap-select .dropdown-item:focus{background-color:#073e65 !important;color:#fff;}

.input-field--MAIN .dropdown.bootstrap-select{margin: 0;}
.input-field--MAIN .dropdown.bootstrap-select button{padding:7px 10px;border-color:#d3d4d7;}
.input-field--MAIN .dropdown.bootstrap-select button:hover,
.input-field--MAIN .dropdown.bootstrap-select.show .btn-light.dropdown-toggle,
.input-field--MAIN .dropdown.bootstrap-select button:active,
.input-field--MAIN .dropdown.bootstrap-select button:focus{background:none;color:#999;outline:none !important;}
.input-field--MAIN .dropdown.bootstrap-select li.selected.active .dropdown-item,
.input-field--MAIN .dropdown.bootstrap-select .dropdown-item:hover,
.input-field--MAIN .dropdown.bootstrap-select .dropdown-item:active,
.input-field--MAIN .dropdown.bootstrap-select .dropdown-item:focus{color: #ffffff !important;text-decoration: none !important;background-color: #0a6eb4 !important;}
.input-field--MAIN .dropdown.bootstrap-select .dropdown-menu{padding:0;border-radius:0;}
.input-field--MAIN .dropdown.bootstrap-select.dropup .dropdown-toggle::after,
.edit_billing_info .dropdown.bootstrap-select.dropup .dropdown-toggle::after{border-top: 0.3em solid;border-right: 0.3em solid transparent;border-bottom: 0;border-left: 0.3em solid transparent;margin-left: .255em;vertical-align: .255em;}
.input-field--MAIN .dropdown.bootstrap-select button.btn-light,
.input-field--MAIN .dropdown.bootstrap-select.dropup button.btn-light{color:#495057;}

.edit_billing_info .dropdown.bootstrap-select .dropdown-toggle::after{margin-left:-6px;}
.whiteColoredSelect .select-item--MAIN .dropdown.bootstrap-select button{border-color:#fff;color:#fff;}

.footeSupportLink{padding:5px 10px;border:1px solid #0a6eb4;background:#0a6eb4;border-radius:3px;color:#fff;}
.footeSupportLink .fas{margin:0 5px 0 0;}
.footeSupportLink:hover,.footeSupportLink:focus,.footeSupportLink:active{border-color:#0a6eb4;background:none;color:#0a6eb4;outline:none;text-decoration: none;}
.noStatsRecordFound{padding:0 0 50px;}
button.button--SOLID-SECONDARY.link.btnBlueSecondary{background:#02a1e4;color:#fff;padding:5px 10px;border:1px solid #02a1e4;border-radius:3px;}
button.button--SOLID-SECONDARY.link.btnBlueSecondary:hover,
button.button--SOLID-SECONDARY.link.btnBlueSecondary:focus,
button.button--SOLID-SECONDARY.link.btnBlueSecondary:active{color:#02a1e4;background:none;border-color:#02a1e4;outline:none;}
.contactUsform .form-control{height:auto;padding:7px 12px;}
.input-file-btn-holder {position: relative;width: 56px;height: 22px;margin: 0 0 5px}
.input-file-btn-holder input {width: 100%;height: 100%;display: block;font-size: 0;opacity: 0;position: relative;z-index: 1;cursor: pointer}
.input-file-btn-holder .input-file-btn {width: 100%;height: 100%;position: absolute;left: 0;top: 0;background: #0af;color: #fff;font-size: 12px;line-height: 18px;text-align: center;padding: 3px 5px 0;}
.dashboardActionButtons button{margin:0 10px 0 0 !important;}
.dashboardActionButtons button:focus{outline: none;}
.topDevicesHolder,
.topDevicesHolder .detailHolder{overflow: hidden;}
.topDevicesHolder .deviceHolder{padding:20px;border-radius: 7px;font-size:18px;margin:0 0 20px;}
.topDevicesHolder .deviceHolder hr{background: #fff;}
.topDevicesHolder .iconHolder{width:80px;float:right;margin: 0 0 0 15px;}
.topDevicesHolder .iconHolder img,
.topDevicesHolder .iconHolder i{opacity:0.7}
.topDevicesHolder .iconHolder i{font-size:70px;}
.topDevicesHolder .detailHolder .deviceName{font-size:22px;line-height:22px;}
.topDevicesHolder .detailHolder p{margin:0;}
.noDataHolder{height:calc(100% - 65px);min-height: 50px;}
.noDataHolder .noDataInner{justify-items: center;display: grid;align-items: center;}
.floInkDetailBlock{padding:15px;background:#efeeee;}
.floInkDetailBlock .topThreeFloinkHolder{background:#fff;padding:15px;}
#tableResponsive{overflow-y: hidden;}
.paymenyHistoryTable .numberCol{min-width:40px;font-weight:700;}
.paymenyHistoryTable .historyCol{min-width:500px;}
.paymenyHistoryTable .tableCol{padding:12px;}
.paymenyHistoryTable .tableHead .tableCol{border:solid #dee2e6;border-width:1px 0;font-size:16px;line-height:24px;font-weight:700;}
.paymenyHistoryTable .tableBody .tableCol{border-bottom:1px solid #dee2e6;color:#212529;}
.paymenyHistoryTable .tableBody .tableRow:last-child .tableCol{border:none;}
.paymenyHistoryTable .tableHead{background:#0a6eb4;color:#fff;}
.paymenyHistoryTable .tableBody .tableRow:nth-child(even){background:#f5f5f5;}
.paymenyHistoryTable #my-div{height: 450px;overflow-y: auto;}
.paymenyHistoryTable .customDomainLoaderHolder{padding: 0 0 70px;background:#f5f5f5;}
.paymenyHistoryTable .customDomainLoaderHolder.allDataLoaded{padding: 0;}
/* .campaignChannelsBlock .infinite-scroll-component{padding: 15px 15px 70px;position: relative;} */
.paymenyHistoryTable .customDomainLoaderHolder .css-sgiz3,
.campaignChannelsBlock .infinite-scroll-component .css-sgiz3{top:auto;bottom:9px;}
.campaignChannelsBlock .infinite-scroll-component #your-div{background: lightgray;padding: 15px 15px 70px;position: relative;}
.campaignChannelsBlock .infinite-scroll-component #your-div > div{background: #fff;padding:15px;}
.generated-qr-box{margin-left:15px;}
.css-1ethbf-menu>div>div{cursor: pointer;}
/* select,
select option{
	font-size:16px !important;
	line-height: normal !important;
	font-family: "Proxima" !important;
} */
select option{padding:3px 5px !important;}
select__menu-list::-webkit-scrollbar {
width: 4px;
height: 0px;
}
select__menu-list::-webkit-scrollbar-track {
background: #00f;
}
select__menu-list::-webkit-scrollbar-thumb {
background: #f00;
}
select__menu-list::-webkit-scrollbar-thumb:hover {
background: #0f0;
}

@keyframes lds-ring {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
}
@media (max-width: 1500px) {
	.action-sheet--wrapper-MAIN {width: 100%;overflow: hidden;}
	section.top-banner .banner-content {margin-top: 40px;}
	section.top-banner {padding-bottom: 50px;}
	header .main-header {padding: 4px 0;}
	header .main-header a.logo {width: 100px;}
	.navbar-nav li.nav-item a {font-size: 17px;}
	.navbar-nav li.nav-item.button a {padding: 7px 10px !important;}
	section.top-banner .banner-content h1.banner-title {font-size: 60px;color: white;font-weight: 900;margin-top: 20px;line-height: 75px;text-align: center;margin-bottom: 0;text-transform: uppercase;}
	section.top-banner .banner-content p {font-size: 16px;}
	section.top-banner .banner-bottom .search-field input[type="text"] {height: 50px;font-size: 18px;padding: 0 10px;}
	section.top-banner .banner-bottom .search-field button.genrate {height: 50px;font-size: 18px;}
	section.first-section span.heading h2 {display: inline-block;font-size: 30px;padding: 25px 0;line-height: 36px;width: 100%;}
	.content-title {font-size: 30px;font-weight: bold;line-height: 30px;margin-top: 10px;margin-bottom: 15px;}
	.content-title span {font-size: 15px;}
	.content-info p {font-size: 16px;line-height: 22px;}
	.btn.btn-cb {background: #0a6eb4;border: 1px solid #0a6eb4;color: white;font-size: 18px;font-weight: 600;padding: 7px 25px;border-radius: 30px;}
	.btn.btn-cw {background: #e85629;border: 1px solid #e85629;color: #fff;font-size: 18px;font-weight: 600;padding: 7px 25px;border-radius: 30px;}
	section.first-section .row.second span.heading,	section.first-section .row.third span.heading {padding-top: 60px;margin-bottom: 40px;}
	section.first-section .row.second span.heading p, section.first-section .row.third span.heading p {font-size: 16px;}
	.row.second .item span.title {font-size: 20px;}
	.row.second .item p {font-size: 15px;line-height: 22px;min-height: 90px;margin-bottom: 35px;}
	.row.second, .row.third {margin-top: 50px;}
	section.before-footer {padding: 40px 0;}
	section.before-footer span {font-size: 50px;}
	footer {background: #f7f7f7;padding-top: 60px;}
	footer .readmore{display:none;}
	footer .container p {font-size: 16px;}
	footer span.title {font-size: 20px;}
	footer ul li a {font-size: 14px;}
	.cms-content {margin-top: 60px;}
	.cms-content h1.title {font-size: 30px;}
	.cms-content .text {font-size: 16px;	}
	.loink-content-section{margin-bottom: 60px;}
	.qrcode-block{min-height:calc(100vh - 456px);}
}
@media (max-width: 1200px) {
	.navbar-nav li.nav-item a {font-size: 15px;	}
	section.top-banner .banner-content h1.banner-title {font-size: 50px;line-height: 65px;	}
	section.top-banner .banner-content p {font-size: 16px;}
	section.top-banner .banner-bottom .search-field {padding: 20px 30px;}
	section.top-banner .banner-bottom .search-field input[type="text"] {height: 50px;}
	section.top-banner .banner-bottom .search-field button.genrate {height: 50px;}
	section.top-banner .banner-content {margin-top: 80px;}
	section.top-banner {padding-bottom: 80px;}
	section.first-section span.heading {padding-top: 110px;}
	section.first-section span.heading:before {height: 70px;}
	section.first-section span.heading h2 {font-size: 32px;}
	section.first-section span.heading {margin-bottom: 30px;}
	.content-title {font-size: 32px;}
	.content-info p {font-size: 16px;}
	.btn.btn-cb {font-size: 18px;}
	.btn.btn-cw {font-size: 18px;}
	section.first-section .row.second span.heading,
	section.first-section .row.third span.heading {padding-top: 50px;margin-bottom: 30px;}
	section.first-section .row.second span.heading p, section.first-section .row.third span.heading p {font-size: 16px;}
	.row.second .item span.title {font-size: 20px;line-height: 20px;}
	.row.second .item p {font-size: 16px;line-height: 25px;}
	section.before-footer {padding: 50px 0;}
	section.before-footer span {font-size: 40px;}
	footer p {font-size: 16px;	}
	footer span.title {font-size: 22px;}
	footer ul {padding: 0;margin-top: 10px;margin-bottom: 20px;}
	.socail-links ul li a {font-size: 15px;}
	.socail-links ul li {width: 35px;height: 35px;}
	footer ul li a {font-size: 14px;}
	.left-content{float: left;width: calc(100% - 80px);}
	.performance-summary--total span.icon {font-size: 65px;line-height: 60px;width: 80px;}
}
@media (max-width: 1199px) {
	.coming-soon-block{min-height:calc(100vh - 331px);}
	.elementor-price-table__ribbon.popular .elementor-price-table__ribbon-inner{transform:translateY(-96%) translateX(-40%) translateX(25px) rotate(-45deg);}
	.special-feature-holder{min-height:200px;}
	.staticPageHeader{min-height:250px;}
	.contentArticlesHolder,
	.solutionDetailsBlock{padding:0 0 100px;}
	.sectionIntroText{margin:0 0 30px;}
	.btn.btn-cb, .btn.btn-cw{
		padding:12px 17px;
		font-size:16px;
	}
	.paymenyHistoryTable{width:962px;}
}
@media (max-width: 991px) {
	div#navbarResponsive ul.navbar-nav li.nav-item {border-bottom: 1px solid #e8e9eb;}
	.btn.btn-cw {font-size: 15px;}
	.btn.btn-cb {font-size: 15px;}
	.row.second .item {padding: 20px 15px;}
	.socail-links ul li {width: 30px;height: 30px;padding: 1px 0;}
	.layout--wrapper ul.layout--navigation li a.clickable-item {font-size: 15px;}
	.layout--navigation-item.selected:before {border-top: 17px solid transparent;border-bottom: 15px solid transparent;border-left: 22px solid #ebecee;}
	.subscription-pricing--layout
		.subscription-pricing--tiers
		.subscription-pricing-panel--row
		.subscription-pricing-summary--panel {margin-bottom: 40px;}
	.subscription-pricing--hidden-mobile {display: none;}
	.subscription-pricing--hidden-desktop {display: block;}
	.sticky-upgrade--footer {display: none;}
	.subscription-payment--layout form .subscription-payment--left-panel {border-bottom: 0;border-right: none;padding-right: 0;}
	.subscription-payment--right-panel {padding-left: 15px;padding-right:15px;}
	.subscription-payment--layout form .subscription-payment--left-panel .options .row {margin: 0;padding: 0;}
	section.top-banner .banner-content {margin-top: 50px;}
	section.top-banner .banner-content h1.banner-title {font-size: 40px;line-height: 45px;}
	section.top-banner .banner-bottom {margin-top: 30px;margin-bottom: 0;}
	section.top-banner {padding-bottom: 60px;}
	section.top-banner .banner-bottom .search-field input[type="text"] {height: 40px;font-size: 20px;	}
	section.top-banner .banner-bottom .search-field button.genrate {height: 40px;}
	section.top-banner .banner-bottom .search-field p {font-size: 16px;}
	section.first-section span.heading h2 {font-size: 28px;}
	.content-title {font-size: 25px;}
	section.before-footer {padding: 40px 0;}
	section.before-footer span {font-size: 30px;}
	section.before-footer .btn.btn-cw {margin-top: 10px;}
	section.first-section {padding-bottom: 60px;}
	section.top-history {height: auto;}
	section.all-features {height: auto;background: #efeeee;}
	.performance-summary-dashboard--MAIN {background-color: #efeeee;padding: 15px 30px;height: 100%;padding-bottom: 50px;}
	.left-content{float: left;width: calc(100% - 140px);}
	.performance-summary--total span.icon {font-size: 100px;line-height: 80px;width: 140px;}
	.coming-soon-block{min-height:calc(100vh - 537px);}
	.elementor-price-table__ribbon.popular .elementor-price-table__ribbon-inner{transform:translateY(-62%) translateX(-45%) translateX(25px) rotate(-45deg);font-size:20px;}
	.cms-content {margin-top: 40px;}
	.floink-content-section{margin-bottom:40px;}
	.special-feature-holder{margin:0 0 25px;min-height:auto;}
	.solution-section{padding:30px 0;}
	section.before-footer span, .before-footer span{font-size:40px;line-height: 45px;}
	section.links-list a.nav-link span.campaign-title{font-size:16px;padding:2px 10px;}
	header .menu-outsite{width:calc(100% - 30px);}
	header .menu-outsite span.group button.btn{margin-left:8px;padding:10px;width: 82px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
	header .menu-outsite .create{padding-right:8px;}
	.main-header .navbar-collapse,
	.main-header .navbar-collapse.collapsing{position: absolute;left: 0;right: 0;top: 100%;background: #fff;z-index:1;}
	.nav-link.js-scroll-trigger{margin:0;}
	.navbar-nav li.nav-item.colr,
	.navbar-light .navbar-nav .nav-item{margin-right:0;}
	div#navbarResponsive ul.navbar-nav li.nav-item.upper,
	div#navbarResponsive ul.navbar-nav li.nav-item.colr {border:none}
	.navbar-nav li.nav-item.upper .nav-link.js-scroll-trigger,
	.navbar-nav li.nav-item.colr .nav-link.js-scroll-trigger{margin:5px;}
	.navbar-nav li.nav-item a:hover,
	.navbar-nav li.nav-item a.active{border-radius:0;}
	.qrcode-block{min-height:calc(100vh - 511px);}
	.navbar-nav li.nav-item.colr .nav-link.js-scroll-trigger:hover,
	.navbar-nav li.nav-item.colr .nav-link.js-scroll-trigger.active{border-radius:4px;}
	.solution-section .heading-block h2{font-size:28px;line-height: 30px;}
	.solution-section .heading-block h4{font-size:22px;line-height: 24px;}
	.staticPageHeader{min-height:200px;}
	.staticPageHeader h1{font-size:50px;line-height:55px;}
	.sectionHeadingBlock{padding: 80px 0 30px;}
	.solutionTextBlock{padding:80px 0;}
	.sectionHeadingBlock:after{height:70px;}
	.contentArticlesHolder,
	.solutionDetailsBlock{padding:0 0 80px;}
	.sectionIntroText{margin:0 0 25px;}
	.sectionHeadingBlock h2, .sectionIntroText h2{font-size:30px;line-height:35px;}
	.solutionTextBlock  .solutionImageHolder{display:inline-block;vertical-align:top;}
	.solutionDetailsBlock .solutionDetailBlock{height:auto;margin:0 0 100px}
	.solutionDetailsRow .col-lg-4:last-child .solutionDetailBlock{margin:0;}
	.headerUserName{margin-left:5px;padding:10px 13px;}
	header .menu-outsite .create a#navbarcreate{padding:9px 25px;}

}
@media (max-width: 767px) {
	section.top-banner .banner-content {margin-top: 50px;}
	section.top-banner {padding-bottom: 50px;}
	section.top-banner .banner-content h1.banner-title {font-size: 35px;line-height: 50px;}
	section.top-banner .banner-bottom .down-arrow {padding-bottom: 60px;position: relative;	}
	section.top-banner .banner-bottom .down-arrow:after {height: 40px;}
	section.top-banner .banner-bottom .search-field input[type="text"] {font-size: 25px;height: 40px;}
	section.first-section span.heading:before {height: 50px;}
	section.first-section span.heading {margin-bottom: 10px;padding-top: 70px;}
	section.first-section span.heading:before {top: 20px;}
	.row.first .action-button {margin-bottom: 20px;}
	section.first-section {background-position: right 14%;}
	.row.second .item {padding: 20px 15px;max-width: 400px;margin: 40px auto;}
	.row.second .item p {min-height: auto;margin-bottom: 20px;}
	.row.third .item,
	.row.fourth .item {margin-bottom: 20px;}
	section.first-section {padding-bottom: 40px;background: none;}
	section.before-footer span {font-size: 25px;}
	.socail-links ul {padding-bottom: 45px;}
	section.top-banner .banner-content p {font-size: 15px;padding: 0 10px;}
	section.top-banner .banner-bottom .search-field button.genrate {height: 40px;font-size: 16px;}
	section.top-banner .banner-bottom .search-field p {font-size: 12px;}
	.action-button button.btn.btn-cw {margin-right: 2px;}
	.row.first .action-button a{margin: 0 5px;}
	.btn.btn-cw,
	.btn.btn-cb {padding: 9px 15px;}
	.container-box {padding: 50px 25px;width: 95%;}
	.social-sign-in {text-align: center;}
	.button.susi-btn {width: 70px;}
	header .menu-outsite form.form-inline {width: calc(100% + 60px);max-width: calc(100% + 60px);margin: 0;float: none;margin-top: 15px;display: inline-block;margin-bottom: 10px;}
	header form.form-inline button {top: 0;}
	header .links {float: left;display: none;}
	#nav {padding: 0 0 70px 0;}
	.action-sheet--wrapper-MAIN {width: calc(100% + 0px);overflow: hidden;}
	.hide-desktop {display: block;}
	.action-sheet--content-wrapper {width: 100%;}
	section.top-chart {display: none;}
	section.links-list {padding: 0 15px;}
	section.links-list .nav.nav-pills {width:100%;}
	section.links-list .tab-content .no-content-holder {margin-left: -15px;padding: 0 15px;border-top: 1px solid lightgray;margin-top: -8px;}
	.subscription-pricing--title {width: 100%;margin: 0 auto;}
	h1.subscription-title--title {text-transform: uppercase;margin-bottom: 10px;margin-top: 15px;font-size: 25px;}
	p.subscription-title--blurb {font-size: 16px;line-height: 25px;}
	.subscription-payment--layout form .subscription-payment--left-panel .options .col-md-4.pr-0.pl-0 {padding-left: 15px !important;padding-right: 15px !important;}
	.notfound .notfound-404 {height: 142px;}
	.notfound .notfound-404 h1 {font-size: 112px;}
	header.user-admin .main-header {height: auto;}
	section.all-features .info-wrapper--DARK {border-right: none;width: 100%;}
	.performance-summary-dashboard--MAIN .filter-list--DARK {float: right;margin-bottom: 10px;width: 100%;text-align: center;}
	.performance-summary-dashboard--MAIN h3.performance-summary--title {font-size: 20px;float: left;width: 100%;text-align: center;}
	section.all-features .info-wrapper--DARK{margin-top: 20px;min-height: auto;}
	.filters-bar .filter-list--BLUE {margin-top: 5px;}
	.bottom-copyrights p.copyrights span{display:block;float:none !important;}
	.cms-content .text{margin-bottom:15px;}
	.row.two-columns .text span.title{margin-top:15px;}
	.cms-content .two-columns .image{margin:0 auto 15px;}
	.first-section .row.first{text-align: center;}
	.cms-content {margin-top: 20px;margin-bottom:20px;}
	.floink-content-section{margin-bottom:20px;}
	section.all-features .info-wrapper--DARK{height:auto;padding-bottom:55px;}
	.showOnMobile{display: block;}
	span.clipboard{margin: 0 0 0 5px;}
	section.links-list .nav.nav-pills{height:100%;padding:55px 0 20px;}
	section.links-list .tab-content .no-content-holder,
	section.links-list.campaign .tab-content .no-content-holder{height:40vh}
	.dsbhrd-left-content-holder{max-height: 200px;}
	.single-feature-holder .feature-image-holder{float:none;width:100%;margin:0 0 15px;}
	.btn-refresh-floinks{float:left;margin:5px 0 0 5px;}
	.faqsBlock{padding:0 0 30px;}
	.staticPageHeader h1{font-size:40px;line-height:45px;}
	.sectionHeadingBlock{padding: 50px 0 20px;}
	.solutionTextBlock{padding:50px 0;}
	.sectionHeadingBlock:after{height:40px;}
	.contentArticlesHolder,
	.solutionDetailsBlock{padding:0 0 50px;}
	.sectionHeadingBlock h2, .sectionIntroText h2{font-size:25px;line-height: 29px;}
	header .menu-outsite form.form-inline{width:100%;max-width: 100%;float:left;}
	.generated-qr-box{margin-left:0;padding-top:18px;}
}
@media (max-width: 575px) {
	section.top-banner .banner-bottom .search-field input[type="text"]{width:100%;margin:0 0 15px;}
	section.top-banner .banner-bottom .search-field button.genrate{width:100%;}
}
@media (max-width: 500px) {
	header .menu-outsite {display: inline-block;width: calc(100% - 29px);}
	header .menu-outsite .create {padding-right: 5px;}
	header .menu-outsite span.group {margin-left: 0;margin-top: 6px;}
	header .menu-outsite span.group button.btn {padding: 6px 5px;margin-left: 5px;font-size: 12px;}
	header .menu-outsite .create a#navbarcreate {padding: 6px 10px;font-size: 12px;}
	header .menu-outsite form.form-inline {width: calc(100% + 40px);max-width: calc(100% + 40px);}
	.menu{top: 6px;}
	.account-detail--type .account-detail--name {width: 100%;}
	.account-detail--type .account-detail--info {width: 100%;margin-top: 20px;}
	.account-detail--type .account-detail--info ul {list-style: none;padding: 0;}
	section.top-banner {padding-bottom: 30px;background: #1564b1;}
	section.top-banner .banner-content {margin-top: 0;}
	section.top-banner .banner-content h1.banner-title {font-size: 22px;line-height: 30px;}
	section.top-banner .banner-bottom {margin-top: 10px;margin-bottom: 0px;}
	section.top-banner .banner-bottom .search-field {padding: 10px 10px;}
	section.top-banner .banner-bottom .search-field input[type="text"] {font-size: 18px;height: 35px;}
	section.top-banner .banner-bottom .search-field button.genrate {height: 35px;font-size: 13px;}
	section.first-section span.heading h2 {font-size: 20px;padding: 11px 0;line-height: 25px;}
	.content-title span {font-size: 14px;}
	.content-title {font-size: 20px;}
	section.first-section span.heading:before {height: 30px;}
	section.first-section span.heading {margin-bottom: 10px;padding-top: 60px;}
	.content-info p {font-size: 15px;}
	section.first-section .row.second span.heading,
	section.first-section .row.third span.heading {padding-top: 40px;margin-bottom: 10px;}
	.row.second .item {margin: 20px auto;}
	section.before-footer {padding: 30px 0;}
	section.before-footer span {font-size: 25px;line-height: 35px;}
	footer span.title {font-size: 20px;}
	footer ul li a {font-size: 14px;}
	.bottom-copyrights p.copyrights {font-size: 13px;}
	.left-content {float: left;width: calc(100% - 50px);}
	.performance-summary--total span.icon {font-size: 40px;line-height: 40px;width: 50px;}
	.performance-summary-dashboard--MAIN {padding: 15px 15px;}
	section.all-features{padding: 0 15px 20px;}
	.performance-summary-dashboard--MAIN .filter-list--DARK .button--MAIN {float: left;margin:0 10px 10px 0;}
	div#shorten_actions ul#most_recent_links span span.short-link{padding:0;}
	div#shorten_actions ul#most_recent_links span.link-prev-text{display:block;}
	section.before-footer span, .before-footer span{font-size:32px;line-height: 36px;margin:0 0 15px;}
	.performance-summary-dashboard--MAIN .filter-list--DARK .button--MAIN,
	.performance-summary-dashboard--MAIN .filter-list--DARK .float-right{width:100%;display:block;float:none !important;margin:0 0 5px;}
	.qrcode-block .qrcode-holder{min-width:290px;max-width: 290px;;}
	.resulted-domains .float-left,
	.resulted-domains .float-right,
	.empty-state--BITLINKS .float-left,
	.empty-state--BITLINKS .float-right{float:none !important;width:100%;display:block;text-align:left;margin:0 0 5px;}
	.admin-section--MAIN span.account-detail--header, .admin-section--MAIN .admin-section--header{font-size:22px;}
	.admin-section--MAIN button.button--SOLID-PRIMARY{margin:0 0 5px;}
	.admin-section--top .admin-section--item p.marketing-module--text{padding:0;}
	.bitlink--copy-wrapper{padding:5px 0;}
	.bitlink--copy-interface a.bitlink--copyable-text{float:none;}
	section.links-list .bitlink--copy-interface .item--action-buttons button.button--ACTION{margin-left: 0;}
	.container-box .header h3.tagline{font-size:22px;}
	.paymentMessageParent .messageBlock{min-width:290px;}
	.staticPageHeader{min-height:150px;}
	.staticPageHeader h1{font-size:30px;line-height:35px;}
	header .menu-outsite .create a#navbarcreate{padding:6px;}
	.sectionHeadingBlock h2{font-size:26px;line-height: 30px;}
	.headerUserName{margin-left:5px;padding: 7px 6px 5px;font-size: 12px;line-height:16px;}
}
@media (min-width: 667px) {
	#nav {padding: 0 0 70px 0;}
	.admin-section--MAIN table.table {display: inline-table;}
}
.btn-verify-email {width: 100px;height: 40px;font-size: 18px !important;background: #0a6eb4 !important;border: 1px solid #0a6eb4 !important;border-radius: 4px;color: white !important;}
.btn-outline-verify-email {width: 100px;height: 40px;font-size: 18px !important;border: 1px solid #0a6eb4 !important;border-radius: 4px;color: #0a6eb4 !important;}
.btn-download {background-color: #0a6eb4 !important;}
textarea {resize: none;}
.tab-pane {width: 100%;}
.bootstrap-select.my-select .dropdown-menu {width: 100%;}